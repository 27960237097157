import React, { useContext, useEffect, useRef, useState } from "react";
import { Input, MessageList, Popup } from "react-chat-elements";
import { Button, InputGroup, InputNumber, Whisper } from "rsuite";
import { CentralContext } from "../../context";
import { screenSize } from "../../hooks";
import socket from "../../socket";
import soundFX from "../../soundfx";
import Options from "./options";
import PrivateBar from "./privateBar";

import styles from "./styles.module.css";
import "./styles.css";

const Chat = (props) => {
  const cntx = useContext(CentralContext);
  const { height, width, mobile } = screenSize();
  const inputEl = useRef(null);
  const inputRef2 = useRef(null);
  const [showPop, setShowPop] = useState(false);
  const [chatVisible, setChatVisible] = useState(true);
  const [currentChat, setCurrentChat] = useState(0);
  const [tab, setTab] = useState(0);
  const [newChatAlert, setNewChatAlert] = useState(0);
  const [chatMsgCount, setChatMsgCount] = useState(0);
  const [chats, setChats] = useState([
    {
      id: 0,
      channel: {
        name: "Predsoblje",
      },
      msgs: [],
    },
  ]);
  const [winner, setWinner] = useState({
    user: "ime",
    picture: "",
  });
  const [input, setInput] = useState("");
  const [chatMsgs, setChatMsgs] = useState([]);
  const [ytOffer, setYtOffer] = useState({
    show: false,
    ignore: false,
  });
  const DEBUG = false;

  const preSetInput = (value) => {
    let VID_REGEX =
      /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    //value.match(VID_REGEX)[1];
    let ytlink = value.match(VID_REGEX);
    console.log(ytlink, !ytOffer.ignore);
    if (
      ytlink &&
      ytlink.length > 0 &&
      value.match(VID_REGEX)[1] &&
      !ytOffer.ignore
    ) {
      setYtOffer({
        show: true,
        videoId: value.match(VID_REGEX)[1],
        price: 1,
      });
    } else {
      setInput(value);
    }
  };

  useEffect(() => {
    //console.log("restoring chats", cntx.chats.length, chats.length);
    if (cntx.chats.length && chats.length == 1) {
      setChats([...cntx.chats]);
      setCurrentChat(0);
    }
    const chatListener = (msgs) => {
      if (DEBUG) console.log("msgs:", msgs);
      addChatMsgs(msgs);
    };
    socket.setupListener("chat", chatListener);
    socket.emit("recentChats");
    socket.setupListener("recentChats", (cha) => {
      //console.log("cha", cha);
      cha.forEach((cmsgs, i) => {
        addChatMsgs(cmsgs);
      });
      resetUnreadForAll();
    });
    socket.emit("getChatHistory");

    socket.setupListener("playerWon", (data) => {
      setWinner(data);
      setShowPop(true);
    });
    if (DEBUG) console.log("chat on");
    return () => {
      //console.log("destroying chat component, last active:", currentChat);
      cntx.setCurrentChat(currentChat);
      if (DEBUG) console.log("chat off");
      socket.off("chat", chatListener);
    };
  }, []);

  useEffect(() => {
    //console.log("chats updated", chats.length);
    cntx.setChats(chats);
    cntx.setUnreadChats(newChatAlert);
    for (let ch = 0; ch < chats.length; ch++) {
      for (let m = 0; m < chats[ch].msgs.length; m++) {
        const triggerRef = React.createRef();
        chats[ch].msgs[m].title = (
          <Whisper
            trigger="click"
            placement="auto"
            speaker={
              <Options
                content={chats[ch].msgs[m].data}
                onSelect={(key, data) => {
                  onSelect(key, data);
                  if (triggerRef.current && triggerRef.current.close) {
                    triggerRef.current.close();
                  } else {
                    console.log("lost trigger");
                  }
                }}
              />
            }
          >
            <div>{chats[ch].msgs[m].data.user.name}</div>
          </Whisper>
        );
      }
    }
  }, [chats]);

  useEffect(() => {
    //console.log("currentChat", currentChat);
    cntx.setCurrentChat(currentChat);
    if (currentChat > 0) setTab(1);
    else setTab(0);
  }, [currentChat]);

  const sendMsg = () => {
    if (inputEl?.current?.value) {
      socket.emit("chat", {
        msg: inputEl.current.value,
        to: chats[currentChat].id,
      });
      inputEl.current.value = "";
      if (props.mobile) {
        setTimeout(() => {
          //window.scrollTo(0,document.body.scrollHeight)
          document.getElementById("bottom").scrollIntoView(); //behavior: "smooth", block: "end", inline: "nearest"
        }, 200);
      }
    } else {
      console.log("no input ref");
    }
  };

  const addChatMsgs = (msgs) => {
    let newMsgs = [];
    let roomId = 0;

    msgs.forEach((msg, i) => {
      let pos = "left";
      roomId = msg.room;
      soundFX.playSpecific(msg?.user?.sound || 0);
      const triggerRef = React.createRef();
      let newMsg = {
        position: pos,
        type: "text",
        text: msg.text,
        date: new Date(msg.time),
        title: (
          <Whisper
            trigger="click"
            placement="auto"
            speaker={
              <Options
                content={{ ...msg, user: { ...msg.user, id: msg.user._id } }}
                onSelect={(key, data) => {
                  onSelect(key, data);
                  if (triggerRef.current && triggerRef.current.close) {
                    triggerRef.current.close();
                  } else {
                    console.log("lost trigger");
                  }
                }}
              />
            }
          >
            <div>{msg.user.basic.name}</div>
          </Whisper>
        ),
        data: {
          muid: msg._id,
          user: { ...msg.user.basic, id: msg.user._id },
        },
        avatar: msg.user.basic.picture,
      };
      newMsgs.push(newMsg);
    });
    setChats((oldChannels) => {
      console.log("channels", oldChannels);
      let channels = [...oldChannels];
      let currentChannel = channels.find((channel, arrI) => {
        if (channel.id == roomId) return channel;
      });
      if (!currentChannel && roomId.indexOf && roomId.indexOf("_") == -1) {
        console.log("get the image here", msgs);
        currentChannel = {
          id: roomId,
          channel: {
            name: msgs[0].user.basic.name,
            picture: msgs?.[0]?.user?.basic?.picture || msgs?.[0]?.avatar,
          },
          msgs: newMsgs,
          unread: 0,
        };
        channels.push(currentChannel);
        setNewChatAlert(true);
      } else if (roomId.indexOf && roomId.indexOf("_") != -1) {
        currentChannel = channels[0];
        currentChannel.id = roomId;
        currentChannel.channel.name = "Soba";
      } else if (roomId == 0 || roomId == "0") {
        currentChannel = channels[0];
        currentChannel.id = 0;
        currentChannel.channel.name = "Predsoblje";
      } else if (!currentChannel) {
        currentChannel = channels[0];
        currentChannel.id = 0;
        currentChannel.channel.name = "Predsoblje";
      }
      let oldMsgs = currentChannel.msgs;
      let lastPos = oldMsgs.length
        ? oldMsgs[oldMsgs.length - 1].position
        : "left";
      let lastUser = oldMsgs.length
        ? oldMsgs[oldMsgs.length - 1].data.user.id
        : "0";
      newMsgs.forEach((nmsg, i) => {
        if (DEBUG) console.log("user", nmsg.data.user, "last user", lastUser);
        if (lastUser == nmsg.data.user.id) {
          nmsg.position = lastPos;
        } else nmsg.position = lastPos == "left" ? "right" : "left";
        lastPos = nmsg.position;
        lastUser = nmsg.data.user.id;
        currentChannel.msgs.push(nmsg);
        if (currentChannel.id != chats[currentChat].id) {
          currentChannel.unread++;
        }
        //console.log(currentChannel.id, chats[currentChat].id, currentChat);
      });
      currentChannel.msgs = currentChannel.msgs.filter(
        (m, index, self) =>
          index === self.findIndex((t) => t.data.muid === m.data.muid),
      );
      return channels;
    });
    setChatMsgCount((cmc) => {
      return cmc + 1;
    });
  };

  const msgClick = (msg) => {
    //console.log("msg", msg);
  };

  const setChatWindow = (roomId) => {
    //console.log("setting current chat window", roomId);
    let currentChannel;
    let currentChannelI = chats.findIndex((channel, arrI) => {
      if (channel.id == roomId) {
        currentChannel = channel;
        return arrI;
      }
    });
    resetUnreadForChat(currentChannel);
    if (currentChannelI == -1 || currentChannelI == 0) {
      setCurrentChat(0);
      setChatVisible(true);
      setTab(0);
    } else {
      setCurrentChat(currentChannelI);
      setChatVisible(true);
      resetUnreadForChat(currentChannel);
      setTab(1);
    }
    cntx.setCurrentChat(currentChannel);
  };

  const resetUnreadForChat = (chat) => {
    setChats((oldChats) => {
      return oldChats.map((ch) => {
        if (ch?.id == chat?.id) {
          //console.log("reset unread to 0");
          ch.unread = 0;
        }
        return ch;
      });
    });
  };

  const resetUnreadForAll = (chat) => {
    setChats((oldChats) => {
      return oldChats.map((ch) => {
        ch.unread = 0;
        return ch;
      });
    });
  };

  const resetUnreadForCurrentChat = () => {
    setChats((oldChats) => {
      let unreads = 0;
      return oldChats.map((ch, i) => {
        if (ch?.id == chats[currentChat]?.id) {
          ch.unread = 0;
        }
        unreads += i > 0 && ch.unread > 0 ? 1 : 0;
        setNewChatAlert(unreads);
        return ch;
      });
    });
  };

  const onSelect = (item, data) => {
    switch (item) {
      case "profile":
        break;
      case "pvt":
        let currentChannel = 0;
        //console.log("chats before", chats.length);
        setChats((oldChats) => {
          let ch = [...oldChats];
          currentChannel = oldChats.findIndex((channel) => {
            if (channel.id == data.user._id || channel.id == data.user.id)
              return channel;
          });
          //console.log("currentChannel", currentChannel);
          console.log("on select", data);
          if (currentChannel == -1) {
            let newChat = {
              id: data.user.id,
              channel: {
                name: data?.user?.basic?.name || data?.user?.name,
                picture: data?.user?.basic?.picture || data?.user?.picture,
              },
              msgs: [],
            };
            ch.push(newChat);
            currentChannel = ch.length - 1;
            setCurrentChat(currentChannel);
            //setChatWindow(newChat);
            if (cntx.mobileView != 2) {
              cntx.setMobileView(2);
            }
            //console.log("get chat history for pvt");
            socket.emit("getChatHistory", { room: data.user.id });
          } else {
            //console.log("setting current channal", currentChannel);
            setCurrentChat(currentChannel);
            setTab(1);
            //console.log("chat set from cntx");
            if (cntx.mobileView != 2) {
              cntx.setMobileView(2);
            }
          }
          return ch;
        });
        //console.log("chats after", chats.length);
        break;
      case "gift":
        //throw new Error("bla")
        console.log("player sends gift", item, data);
        socket.emit("gift", { to: data.user.id, giftId: data.gift });
        break;
      default:
    }
  };

  const closeChat = (chatId) => {
    let currentChannel = 0;
    setChats((oldChats) => {
      let ch = [...oldChats];
      currentChannel = oldChats.findIndex((channel) => {
        if (channel.id == chatId) return channel;
      });
      if (currentChannel > 0) {
        ch.splice(currentChannel, 1);
      }
      return ch;
    });
    setCurrentChat(0);
  };

  useEffect(() => {
    //console.log("on selected option");
    if (props.selectedOption) {
      onSelect(props.selectedOption.key, {
        user: {
          id: props.selectedOption.data.id,
          basic: {
            ...props.selectedOption.data,
            id: props.selectedOption.data.id,
          },
        },
        gift: props.selectedOption.data.gift,
      });
    }
  }, [props.selectedOption]);

  useEffect(() => {
    setChats((oldChats) => {
      let channels = [...oldChats];
      let currentChannel = channels[0];
      currentChannel.id = props.currentRoom;
      currentChannel.channel.name =
        props.currentRoom == 0 ? "Predsoblje" : "Soba";
      currentChannel.unread = 0;
      return channels;
    });
    setCurrentChat(0);
  }, [props.currentRoom]);

  useEffect(() => {
    //console.log("cmc:", chatMsgCount);
    resetUnreadForCurrentChat();
  }, [chatMsgCount]);

  useEffect(() => {
    setTab(props.tab);
    if (props.tab == 0) {
      setCurrentChat(0);
      setChatVisible(true);
    } else if (props.tab == 1) {
      setChatVisible(false);
      setNewChatAlert(false);
    }
  }, [props.tab]);

  useEffect(() => {
    if (inputRef2?.current)
      inputRef2.current.input.scrollIntoView({ behavior: "smooth" });
  }, [ytOffer.price]);

  useEffect(() => {
    console.log("playlist", cntx.playlist);
  }, []);

  const scrollToBottom = () => {
    const mlistElement = document.getElementsByClassName("rce-mlist")[0];
    if (typeof mlistElement !== "undefined") {
      mlistElement.scrollTop = mlistElement.scrollHeight;
    }
  };

  scrollToBottom();

  return (
    <div
      style={
        !props.mobile
          ? { minHeight: 471 }
          : { height: "100%", maxHeight: "calc(--screenHeight - 50px)" }
      }
    >
      <PrivateBar
        mobile={props.mobile}
        currentChat={currentChat}
        setCurrentChat={setChatWindow}
        chats={chats}
        closeChat={closeChat}
        hideChat={() => setChatVisible(false)}
        showChat={() => setChatVisible(true)}
        chatVisible={chatVisible}
        tab={tab}
        newChatAlert={newChatAlert}
        setNewChatAlert={setNewChatAlert}
        resetUnreadForChat={resetUnreadForChat}
        resetUnreadForCurrentChat={resetUnreadForCurrentChat}
      />
      <div style={{ display: chatVisible ? undefined : "none" }}>
        <MessageList
          className={
            props.mobile ? styles.mobileMessageList : styles.messageList
          }
          lockable={true}
          toBottomHeight={"100%"}
          dataSource={
            chats[currentChat] ? chats[currentChat].msgs : [...chats[0].msgs]
          }
          onClick={msgClick}
        />
        <Input
          referance={inputEl}
          placeholder="Ovdje pisi..."
          multiline={true}
          onChange={(e) => {
            preSetInput(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.shiftKey && e.charCode === 13) {
              return true;
            }
            if (e.charCode === 13) {
              sendMsg();
              e.preventDefault();
              if (props.mobile) {
                setTimeout(() => {
                  //window.scrollTo(0,document.body.scrollHeight)
                  document.getElementById("bottom").scrollIntoView(); //behavior: "smooth", block: "end", inline: "nearest"
                }, 200);
              }
              return false;
            }
          }}
          maxHeight={mobile ? 40 : undefined}
          rightButtons={
            <Button
              appearance="primary"
              onClick={() => {
                sendMsg();
              }}
            >
              Šalji
            </Button>
          }
        />
      </div>

      <Popup
        open={ytOffer.show}
        header="Media Link"
        headerButtons={[
          {
            type: "transparent",
            color: "black",
            text: "zatvori",
            onClick: () => {
              setYtOffer({ ...ytOffer, show: false });
            },
          },
        ]}
        renderContent={() => {
          return (
            <div style={{ maxHeight: 200, overflow: "auto" }}>
              {[...cntx.playlist, ytOffer]
                .sort((a, b) => {
                  if (a.price > b.price) return -1;
                  if (a.price == b.price) return 0;
                  if (a.price < b.price) return 1;
                })
                .map((v, i) => {
                  return (
                    <div key={i} style={{ display: "flex" }}>
                      <p>#{i + 1}</p>
                      <img
                        src={`https://img.youtube.com/vi/${v.videoId}/default.jpg`}
                        style={{ padding: 2 }}
                      />
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {v.videoId != ytOffer.videoId && (
                          <p>trenutna vrijednost pozicije: {v.price}</p>
                        )}
                        {v.videoId == ytOffer.videoId && (
                          <InputGroup style={{ width: "100%" }}>
                            <InputGroup.Button
                              onClick={() =>
                                setYtOffer({
                                  ...ytOffer,
                                  price:
                                    ytOffer.price - 1 > 1
                                      ? ytOffer.price - 1
                                      : 1,
                                })
                              }
                            >
                              -
                            </InputGroup.Button>
                            <InputNumber
                              className={"custom-input-number"}
                              ref={inputRef2}
                              min={1}
                              value={ytOffer.price}
                            />
                            <InputGroup.Button
                              onClick={() =>
                                setYtOffer({
                                  ...ytOffer,
                                  price: ytOffer.price + 1,
                                })
                              }
                            >
                              +
                            </InputGroup.Button>
                          </InputGroup>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        }}
        footerButtons={[
          {
            color: "white",
            backgroundColor: "#ff5e3e",
            text: "Ne prikazuj ovaj prozor više",
            onClick: () => {
              setYtOffer({ ...ytOffer, ignore: true, show: false });
            },
          },
          {
            color: "black",
            backgroundColor: "lightgreen",
            text: "Plati i Ubaci",
            onClick: () => {
              setInput("");
              inputEl.current.clear();
              socket.emit("ytAdd", ytOffer);
              setYtOffer({ ...ytOffer, show: false });
            },
          },
        ]}
      />

      <Popup
        open={showPop}
        header={`Cestitke ${winner.name}!`}
        headerButtons={[
          {
            type: "transparent",
            color: "black",
            text: "close",
            onClick: () => {
              setShowPop(false);
            },
          },
        ]}
        renderContent={() => {
          return <img src={winner.picture} />;
        }}
        footerButtons={[
          {
            color: "white",
            backgroundColor: "#ff5e3e",
            text: "Izlaz",
            onClick: () => {
              socket.emit("joinRoom", 0);
              setShowPop(false);
            },
          },
          {
            color: "black",
            backgroundColor: "lightgreen",
            text: "Nastavi igru",
            onClick: () => {
              setShowPop(false);
            },
          },
        ]}
      />
    </div>
  );
};

export default Chat;

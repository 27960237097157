import React, { useState, useEffect } from 'react';
import { Stage, Layer, Rect, Circle, Image } from 'react-konva';
import useImage from "use-image";
import { screenSize } from "../../hooks";
import Board from "./board";

const Game = (props) => {
  const { height, width, mobile } = screenSize();
  const [update, setUpdate] = useState({});
  const [imgX] = useImage('/assets/x.png');

  useEffect(()=>{
    //console.log("update in game", props.update);
    setUpdate(props.update);
  }, [props.update]);

  return (
    // Stage - is a div wrapper
    // Layer - is an actual 2d canvas element, so you can have several layers inside the stage
    // Rect and Circle are not DOM elements. They are 2d shapes on canvas
    /*

    */
    <Stage width={(!props.mobile)?600:width} height={!props.mobile?600:height-90} x={props.mobile?width/2-300:0} y={props.mobile?height/2-300:0} draggable={props.mobile} dragBoundFunc={(!props.mobile)?null:(pos)=>{
          return {
            x: ((width<600 && pos.x>width-600)?(pos.x>0)?0:pos.x:width-630) || 0,
            y: ((height<600 && pos.y>height-600)?(pos.y>0)?0:pos.y:height-630) || 0,
          };
        }}>
      <Layer x={(width<600)?120:50} y={50}>
        <Board update={update} socket={props.socket}/>
      </Layer>
      <Layer>
        <Image image={imgX} x={550} y={0} scaleX={0.75} scaleY={0.75} onClick={()=>props.socket.emit("joinRoom", 0)} onTap={()=>props.socket.emit("joinRoom", 0)} />
      </Layer>
    </Stage>
  );
}
export default Game;

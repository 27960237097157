import {
  useLocation
} from "react-router-dom";
import ScreenSize from "./screenSize";

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export const screenSize = ScreenSize;

export default {
  useQuery: useQuery,
  screenSize: ScreenSize
}

import React from 'react';

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
}

const StatusPages = props => {
  const status = props.match.params.type;

  const paysafe_canceled =
  <div style={style}>
    Korisnik je odustao od plaćanja / Transaction cancelled by user.
    <a href="/">Povratak / Back</a>
  </div>
  const paysafe_success =
  <div style={style}>
    Uspješno plaćanje / Transaction succeeded.
    <a href="/">Povratak / Back</a>
  </div>
  const defaultPage = <p>Unknown status</p>
  let page = defaultPage;
  switch (status) {
    case "canceled":
      page = paysafe_canceled;
      break;
    case "success":
      page = paysafe_success;
      break;
    default:
      page = defaultPage;
  }

  return page;
}

export default StatusPages;

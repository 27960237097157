import React, { useContext, useEffect, useRef, useState } from "react";
import { Avatar, Button, List, Tooltip, Whisper } from "rsuite";
import { CentralContext } from "../../context";
import { screenSize } from "../../hooks";
import socket from "../../socket";
import LargePic from "../Chat/largePic";
import Options from "../Chat/options";
import styles from "./index.module.css";

const PlayerList = (props) => {
  const { height, width, mobile } = screenSize();
  const cntx = useContext(CentralContext);

  const [giftView, setGiftView] = useState(false);
  const [players, setPlayers] = useState([]);
  const playersRef = useRef(players);

  useEffect(() => {
    socket.setupListener("playerList", (players) => {
      console.log(players);
      const uniq = new Map();
      players.forEach((player) => {
        uniq.set(player.id, player);
      });
      setPlayers([...uniq.values()]);
    });
    socket.setupListener("userJoin", (player) => {
      console.log("add to player list", player);
      const uniq = new Map();
      playersRef.current.forEach((p) => {
        uniq.set(p.id, p);
      });

      uniq.set(player.id, player);
      setPlayers([...uniq.values()]);
    });
    socket.setupListener("userLeave", (player) => {
      console.log("remove player from list", player);
      const uniq = new Map();
      playersRef.current.forEach((p) => {
        if (p.id != player.id) uniq.set(p.id, p);
      });
      setPlayers([...uniq.values()]);
    });
    socket.emit("playerList");
  }, []);

  useEffect(() => {
    cntx.setPlayerCount(players?.length || 0);
    playersRef.current = players;
  }, [players]);

  return !props.mobile ? (
    <div className={styles.playerList}>
      {players &&
        players.length &&
        players.map((player) => {
          // console.log("player", player);
          return (
            <LargePic
              avatar={true}
              player={player}
              fallback={player.picture}
              tooltip={player.name}
              mobile={props.mobile}
              setSelectedOption={props.setSelectedOption}
              key={player.id}
            />
          );
        })}
    </div>
  ) : props.mini ? (
    <div
      style={{
        maxHeight: 40,
        overflowY: "hidden",
        overflowX: "scroll",
        whiteSpace: "nowrap",
        maxWidth: "calc(100vw - 200px)",
      }}
    >
      {players &&
        players.length &&
        players.map((player) => {
          return <Avatar src={player.picture} bordered color={"violet"} />;
        })}
    </div>
  ) : (
    <div
      className={styles.playerList}
      style={{ width: "100vw", height: "calc(var(--screenHeight) - 90px)" }}
    >
      <List>
        {players &&
          players.length &&
          players.map((player) => {
            // console.log("player", player);
            const triggerRef = React.createRef();
            return (
              <List.Item>
                <div className={styles.listItem} key={player.id}>
                  <LargePic
                    avatar={true}
                    id={player.id}
                    fallback={player.picture}
                    mobile={props.mobile}
                  />
                  <h3 style={{ maxWidth: 150, overflow: "hidden" }}>
                    {player.name}
                  </h3>
                  <div>
                    {cntx.user.id != player.id && (
                      <Button
                        style={{ margin: "0 0 5px" }}
                        appearance="primary"
                        onClick={() => {
                          props.setSelectedOption("pvt", player);
                        }}
                      >
                        Privatna
                      </Button>
                    )}
                    {cntx.user.id != player.id && (
                      <div>
                        {!giftView != player.id && (
                          <Button
                            appearance="primary"
                            onClick={() => {
                              setGiftView(player.id);
                            }}
                          >
                            Poklon
                          </Button>
                        )}
                        {giftView == player.id && (
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                              onClick={(e) => {
                                props.setSelectedOption("gift", {
                                  ...player,
                                  gift: 1,
                                });
                                setGiftView(false);
                              }}
                            >
                              <p style={{ fontSize: "xxx-large" }}>🍫</p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src="/images/yelo.png"
                                  style={{ width: 20, height: 20 }}
                                />
                                <p>x10</p>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                margin: "0 5px",
                              }}
                              onClick={(e) => {
                                props.setSelectedOption("gift", {
                                  ...player,
                                  gift: 2,
                                });
                                setGiftView(false);
                              }}
                            >
                              <p style={{ fontSize: "xxx-large" }}>🌹</p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src="/images/yelo.png"
                                  style={{ width: 20, height: 20 }}
                                />
                                <p>x100</p>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                              onClick={(e) => {
                                props.setSelectedOption("gift", {
                                  ...player,
                                  gift: 3,
                                });
                                setGiftView(false);
                              }}
                            >
                              <p style={{ fontSize: "xxx-large" }}>💎</p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src="/images/yelo.png"
                                  style={{ width: 20, height: 20 }}
                                />
                                <p>x1000</p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </List.Item>
            );
          })}
      </List>
    </div>
  );
};

export default PlayerList;

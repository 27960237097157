const loadApLxsdk = (load, callback) => {
  const existingScript = document.getElementById('aplx-sdk');
  if (!existingScript && load) {
    const script = document.createElement('script');
    script.src = 'https://cdn.applixir.com/applixir.sdk3.0m.js';
    script.id = 'aplx-sdk';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback)
    callback();
};

export default loadApLxsdk;

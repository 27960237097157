const createjs = window.createjs;

let stage;
let lastDice;
const ogScale = 0.4;
const scale = {
  x: 1,
  y: 1,
};

const spriteSheet = new createjs.SpriteSheet({
  framerate: 30,
  images: ["/assets/Dice-anim.png"],
  frames: [
    [0, 0, 129, 128, 0, -258.5, -181.95],
    [129, 0, 158, 161, 0, -237.5, -169.95],
    [287, 0, 180, 192, 0, -224.5, -154.95],
    [467, 0, 191, 208, 0, -235.5, -149.95],
    [658, 0, 214, 196, 0, -231.5, -152.95],
    [0, 208, 215, 180, 0, -235.5, -158.95],
    [215, 208, 216, 198, 0, -238.5, -128.95],
    [431, 208, 221, 243, 0, -238.5, -101.95],
    [652, 208, 233, 265, 0, -244.5, -99.95],
    [0, 473, 235, 256, 0, -253.5, -115.95],
    [235, 473, 230, 239, 0, -257.5, -129.95],
    [465, 473, 240, 240, 0, -246.5, -125.95],
    [705, 473, 257, 262, 0, -226.5, -109.95],
    [0, 735, 273, 294, 0, -209.5, -106.95],
    [273, 735, 271, 294, 0, -203.5, -116.95],
    [544, 735, 235, 261, 0, -214.5, -132.95],
    [779, 735, 213, 208, 0, -198.5, -149.95],
    [0, 1029, 215, 185, 0, -192.5, -144.95],
    [215, 1029, 198, 201, 0, -205.5, -129.95],
    [413, 1029, 182, 199, 0, -206.5, -130.95],
    [595, 1029, 178, 192, 0, -201.5, -132.95],
    [773, 1029, 173, 168, 0, -212.5, -156.95],
    [0, 1230, 161, 146, 0, -223.5, -172.95],
    [161, 1230, 153, 131, 0, -229.5, -180.95],
  ],
  animations: {
    roll: [0, 23, false, 1],
  },
});

const switchDice = () => {
  stage.removeChild(lastDice);
  stage.addChild(dices["dice" + diceNum]);
  lastDice = dices["dice" + diceNum];
};

const dice = new createjs.Sprite(spriteSheet, "roll");
const name = "Dice";
dice.scaleX = dice.scaleY = ogScale;
dice.x = 150 - 24;
dice.y = 180 - 24;
dice.on("animationend", switchDice);
const dice1 = new createjs.Bitmap("/assets/Dice-1.png");
const dice2 = new createjs.Bitmap("/assets/Dice-2.png");
const dice3 = new createjs.Bitmap("/assets/Dice-3.png");
const dice4 = new createjs.Bitmap("/assets/Dice-4.png");
const dice5 = new createjs.Bitmap("/assets/Dice-5.png");
const dice6 = new createjs.Bitmap("/assets/Dice-6.png");
const dices = {
  dice1,
  dice2,
  dice3,
  dice4,
  dice5,
  dice6,
};
const setDices = () => {
  for (var d = 1; d <= 6; d++) {
    dices["dice" + d].scale = ogScale * scale.x;
    dices["dice" + d].x = (250 - 24) * scale.x;
    dices["dice" + d].y = (250 - 24) * scale.y;
    dices["dice" + d].on("click", function (e) {
      //throwDice();
      console.log("baci baci");
      setNum(1);
    });
  }
};
setDices();
let diceNum = 1;

const addToStage = (stg) => {
  stage = stg;
  stage.addChild(dice);
  lastDice = dice;
  dice.play();
  console.log("dice play");
  //lastDice = dice;
};
const removeFromStage = (stage) => {
  stage.removeChild(dice);
};
const getWidth = () => {
  return dice.getBounds().width * dice.scaleX;
};
const getRealWidth = () => {
  return dice.getBounds().width;
};
const getHeight = () => {
  return dice.getBounds().height * dice.scaleY;
};
const getRealHeight = () => {
  return dice.getBounds().height;
};
const getX = () => {
  return dice.x;
};
const setX = (val) => {
  dice.x = val;
};
const getY = () => {
  return dice.y;
};
const setY = (val) => {
  dice.y = val;
};
const play = () => {
  dice.play();
};
const setNum = (num) => {
  diceNum = num;
  stage.removeChild(lastDice);
  stage.addChild(dice);
  lastDice = dice;
  dice.gotoAndPlay("roll");
};
const setScale = (s) => {
  scale.x = s.x;
  scale.y = s.y;
  dice.scale = ogScale * scale.x;
  dice.x = (150 - 24) * scale.x;
  dice.y = (180 - 24) * scale.y;
  setDices();
};

export default {
  addToStage: addToStage,
  getRealWidth: getRealWidth,
  getRealHeight: getRealHeight,
  setX: setX,
  setY: setY,
  setScale: setScale,
};

export const Point = (x, y) => {
  if(!x) var x=0;
  if(!y) var y=0;
  return {
    x: x,
    y: y
  };
}

//bezier
const B1 = (t)=>{
  return t*t*t;
}
const B2 = (t)=>{
  return 3*t*t*(1-t);
}
const B3 = (t)=>{
  return 3*t*(1-t)*(1-t);
}
const B4 = (t)=>{
  return (1-t)*(1-t)*(1-t);
}
export const getBezier = (percent,C1,C2,C3,C4) => {
  const pos = Point();
  if(C4!=null){
    pos.x = C1.x*B1(percent) + C2.x*B2(percent) + C3.x*B3(percent) + C4.x*B4(percent);
    pos.y = C1.y*B1(percent) + C2.y*B2(percent) + C3.y*B3(percent) + C4.y*B4(percent);
  } else {
    pos.x = C1.x*B1(percent) + C2.x*B2(percent) + C3.x*B3(percent);
    pos.y = C1.y*B1(percent) + C2.y*B2(percent) + C3.y*B3(percent);
  }
  return pos;
}

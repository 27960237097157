import { faSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faArrowsLeftRight,
  faCheck,
  faCog,
  faCoins,
  faGavel,
  faLock,
  faPlay,
  faTrash,
  faVolumeHigh,
  faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Icon } from "@rsuite/icons";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  IconButton,
  Input,
  Message,
  Modal,
  Panel,
  PanelGroup,
} from "rsuite";
import { CentralContext } from "../../context";
import socket from "../../socket";
import sounds from "../../soundfx";
import { FaSvgIcon } from "../misc";

const Settings = (props) => {
  const cntx = useContext(CentralContext);
  const [opened, setOpened] = useState(false);
  const [showAd, setShowAd] = useState(false);
  const [chatSound, setChatSound] = useState(0);
  const [mute, setMute] = useState(0);
  const [error, setError] = useState(0);
  const [currentPanel, setCurrentPanel] = useState(0);
  const [blockedUsersDetails, setBlockedUsersDetails] = useState([]);
  const [yesNo, setYesNo] = useState({
    opened: false,
  });
  const [newName, setNewName] = useState("");
  const [xbon, setXBon] = useState("");
  const [xbonMsg, setXBonMsg] = useState("");

  useEffect(() => {
    socket.off("settings");
    socket.setupListener("settings", (data) => {
      //console.log("settings", data);
      setChatSound(data.sounds.chatSound);
      setMute(data.sounds.mute);
      //console.log("setting settings", data);
      cntx.setSettings(() => {
        return { ...data };
      });
    });
    socket.setupListener("shopError", (data) => {
      //console.log("error", data);
      if (data == 2) {
        setYesNo({
          opened: true,
          title: "Greška",
          description: "Izgleda da ste ovo već kupili",
          yes: "Zatvori",
          yesCb: () => socket.emit("whoAmI"),
        });
      }
      if (data == 1) {
        setYesNo({
          opened: true,
          title: "Greška",
          description: "Nemate dovoljno dukata",
          yes: "Kupi",
          yesCb: () => {
            let win = window.open(
              "https://www.patreon.com/iggyZiggy",
              "_blank",
            );
            win.focus();
          },
          no: "Zatvori",
          noCb: () => socket.emit("whoAmI"),
        });
      }
    });
    socket.setupListener("shopSuccess", (data) => {
      //console.log("error", data);
      if (data?.misc == 1) {
        setYesNo({
          opened: true,
          title: "Uspjeh!",
          description:
            "Proširili ste blok listu za 5 mjesta i sada imate " +
            data.blockLimit,
          yes: "Zatvori",
          yesCb: () => socket.emit("whoAmI"),
        });
      } else {
        setYesNo({
          opened: true,
          title: "Uspjeh!",
          description: "Promijenili ste si ime",
          yes: "Zatvori",
          yesCb: () => socket.emit("whoAmI"),
        });
      }
    });
    socket.setupListener("youAre", (data) => {
      cntx.setUser((c) => {
        return { ...data };
      });
    });
    socket.setupListener("blockList", (data) => {
      setBlockedUsersDetails(data);
    });
    socket.setupListener("blockListUpdated", (data) => {
      socket.emit("getBlockList");
    });
    socket.emit("getSettings");
    if (cntx?.settings?.blockedUsers?.length) {
      socket.emit("getBlockList");
    }
  }, []);

  const unblock = (user) => {
    socket.emit("removeFromBlockList", user);
  };

  const submitXbon = () => {
    console.log("submit:", xbon);
    socket.emit("xbon", xbon);
    setXBon("");
    setXBonMsg("Kod je poslan na provjeru!");
  };

  const watchAds = (rwrdId) => {
    /*function adStatusCallback(status) {
      //console.log('Ad Status: ' + status);
      if(status=="sys-closing")setTimeout(()=>{socket.emit("whoAmI")}, 3000);
      if(status=="ads-unavailable"){
        setOpened(true);
        setError(1);
        if(window.gdsdk){
          //console.log("try to show backup ads");
          window.gdsdk.showAd();
        } //else console.log("no gdsdk");
      }
    }
    //console.log(cntx.user.id);
    var options = {
      zoneId: 3802, // the zone ID from the "Games" page
      devId: 4799, // optional: your developer ID if using s2s callback
      gameId: 5876, // optional: the ID for this game from the "Games" page for s2s callback
      custom1: cntx.user.id, // optional: custom1 value for s2s callback
      custom2: JSON.stringify({rewardId: rwrdId, env: process.env.REACT_APP_NODE_ENV}), // optional: custom2 value for s2s callback
      fallback: 0, // 0 for no fallbacks, 1 will show fallback ads when ads-unavailable
      adStatusCb: adStatusCallback, // optional: function to provide helpful user messages
    };
    window.invokeApplixirVideoUnit(options);*/
    if (window.gdsdk) {
      window.gdsdk.showAd("rewarded");
    } else {
      console.log("no gdsdk");
      setOpened(true);
      setError(1);
    }
  };

  useEffect(() => {
    if (props.showAd && opened) {
      setOpened(false);
    }
  }, [props.showAd]);

  return (
    <CentralContext.Consumer>
      {(context) => (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <div style={{ display: "flex", flex: 1 }}>
            <IconButton
              icon={
                <Icon
                  as={FaSvgIcon}
                  faIcon={mute ? faVolumeXmark : faVolumeHigh}
                />
              }
              size={props.mini ? "md" : "xs"}
              appearance="primary"
              color="orange"
              onClick={() => socket.emit("toggleMute")}
            />
            <IconButton
              icon={<Icon as={FaSvgIcon} faIcon={faCoins} />}
              appearance="primary"
              color="green"
              size={props.mini ? "md" : "md"}
              onClick={() => {
                setShowAd(true);
                setOpened(true);
              }}
              style={{ marginLeft: 2 }}
            >
              {context.user.ducats}
            </IconButton>
            {context.user.admin && (
              <IconButton
                icon={<Icon as={FaSvgIcon} faIcon={faGavel} />}
                size={props.mini ? "md" : "lg"}
                appearance="primary"
                color="orange"
                componentClass={Link}
                to="/admin"
                style={{ marginLeft: 2 }}
              />
            )}
            <IconButton
              icon={<Icon as={FaSvgIcon} faIcon={faCog} />}
              appearance="primary"
              color="orange"
              size={props.mini ? "md" : "xs"}
              onClick={() => setOpened(true)}
              style={{ marginLeft: 2 }}
            />
          </div>
          <Modal
            open={opened}
            onClose={() => {
              setOpened(false);
              setError(0);
            }}
            size={props.mini ? "xs" : "md"}
          >
            <Modal.Header>
              <Modal.Title>
                <ButtonGroup justified>
                  <Button
                    onClick={() => setCurrentPanel(0)}
                    appearance={currentPanel == 0 ? "primary" : "default"}
                  >
                    Nabavi Dukate
                  </Button>
                  <Button
                    onClick={() => setCurrentPanel(1)}
                    appearance={currentPanel == 1 ? "primary" : "default"}
                  >
                    Postavke
                  </Button>
                  <Button
                    onClick={() => setCurrentPanel(2)}
                    appearance={currentPanel == 2 ? "primary" : "default"}
                  >
                    Dućan
                  </Button>
                </ButtonGroup>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {currentPanel == 0 && (
                <div>
                  {!!error && (
                    <div>
                      {error == 1 && (
                        <div>
                          <Message
                            type="error"
                            description={
                              <div>
                                Reklame za dukate nisu dostupne{" "}
                                {document.referrer ==
                                  "https://apps.facebook.com/" && (
                                  <span>
                                    pokusajte na{" "}
                                    <a href="https://cnljs.com">
                                      glavnoj stranici
                                    </a>
                                  </span>
                                )}
                              </div>
                            }
                          />{" "}
                          <Divider />
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/images/yelo.png"
                      style={{ width: 100, height: 100 }}
                    />
                    <p style={{ padding: 10 }}>
                      Dukati su valuta u igri s kojom možete kupovati figure,
                      posebne zvukove, dodavati pjesme na playlistu (media) pa
                      slušati sa ostalim igračima u predsoblju, promjeniti si
                      ime i još toga. Dukate možete zaraditi tako da dolazite
                      svaki dan na igru, gledate reklame i naravno, možete ih
                      kupiti. Kupnjom dukata pomažete igri da se dalje razvija
                      kao i što olakšavate meni plaćanje troškova održavanja. Za
                      sada nas ima malo i puno hvala ljudima koji podržavaju
                      igru na patreonu, kao i onima koji su se javili s
                      jednokratnim uplatama. Hvala Alesio, Alejna, Ibrahim,
                      Miro, Admir, Robi!
                    </p>
                  </div>
                  <Divider />
                  <div>
                    <div>
                      Pogledaj reklamu za{" "}
                      <Button
                        color="green"
                        onClick={() => {
                          watchAds(2);
                          setOpened(false);
                          setError(0);
                        }}
                      >
                        1 Dukat
                      </Button>
                    </div>
                    <div>
                      Budući da neke reklame nisu dozvoljene na facebooku,
                      igrajte na{" "}
                      <a href="https://cnljs.com">glavnoj stranici</a> gdje
                      možete osvojiti više dukata
                    </div>
                    <Divider />
                    <div style={{ display: "flex" }}>
                      <img
                        src="/images/logo_paysafecard.png"
                        style={{ width: "50%" }}
                      />
                      {true && (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <a href="/api/paysafe/pay/1" target="_blank">
                            <Button style={{ marginBottom: 5 }} color="orange">
                              Kupi za €1
                            </Button>
                          </a>
                          <a href="/api/paysafe/pay/5" target="_blank">
                            <Button style={{ marginBottom: 5 }} color="orange">
                              Kupi za €5
                            </Button>
                          </a>
                          <a href="/api/paysafe/pay/10" target="_blank">
                            <Button style={{ marginBottom: 5 }} color="orange">
                              Kupi za €10
                            </Button>
                          </a>
                        </div>
                      )}
                    </div>
                    <p>
                      Ako nemate bankovnu karticu karticu ili iz nekog razloga
                      ne želite uplaćivati preko vaše bankovne kartice postoji
                      opcija plaćanjem <strong>paysafecard</strong>om. To
                      funkcionira tako da kupite paysafecard u željenom iznosu
                      za gotovinu i onda koristite 16 znamenkasti pin kod za
                      plaćanje putem interneta. Ili u ovome slučaju, nakon što
                      kupite paysafecard, kliknete na jedan od ova tri gumba
                      iznad ovo teksta i upišete taj kod na stranici koja vam se
                      otvori. Zatim kada se uplata uspješno izvrši, dobiti ćete
                      uplaćeni iznos u dukatima.
                    </p>
                    <Divider />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/images/xbon.png"
                        style={{ width: 100, height: 100 }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 10,
                        }}
                      >
                        <p>
                          Možete nam poslati x-bon u kojem god iznosu želite i
                          dobivate dukata u vrijednosti kao i za dolare, npr.
                          10km = 6200 dukata
                        </p>
                        {!xbonMsg && (
                          <div>
                            <Input
                              placeholder="1234 1234 123 123"
                              onChange={setXBon}
                            />
                            <Button appearance="primary" onClick={submitXbon}>
                              Pošalji
                            </Button>
                          </div>
                        )}
                        <p>
                          Napomena: provjera x-bona nije instantna pa pričekajte
                          da vam sjedne uplata u dukatima ili nam se javite na
                          fb stranicu
                        </p>
                        {xbonMsg && (
                          <p style={{ color: "darkgreen" }}>{xbonMsg}</p>
                        )}
                      </div>
                    </div>
                    <Divider />
                    <div>
                      Podrži nas preko patreona i za svaki cent dobiješ 10
                      dukata, znači za $1 = 1000 dukata. Mjesečno!{" "}
                      <a
                        target="_blank"
                        href="https://www.patreon.com/iggyZiggy"
                      >
                        <Button color="orange">Patreon</Button>
                      </a>
                    </div>
                    <Divider />
                    <div>
                      Pozovi prijatelje na igru preko ovog linka:{" "}
                      <nobr>
                        <a
                          target="_blank"
                          href={`https://cnljs.com/invite/${context.user.id}`}
                        >{`https://cnljs.com/invite/${context.user.id}`}</a>
                      </nobr>{" "}
                      i za svakog novog igraca dobijes{" "}
                      {process.env.REACT_APP_INVITE_DUCATS_REWARD} dukata, kao i
                      još po dukat za svakih 10 reklama koje taj prijatelj
                      pogleda
                    </div>
                    <Divider />
                    <div>
                      <iframe
                        width="100%"
                        frameBorder="0"
                        height="500px"
                        src={`https://offers.cpx-research.com/index.php?app_id=7494&ext_user_id=${context.user.id}&secure_hash=ofrXkHGoGsvfIpyOl4eJMt4TFaMptjqq&username=${context?.user?.basic?.name ? context.user.basic.name : "player"}`}
                      ></iframe>
                    </div>
                  </div>
                </div>
              )}
              {currentPanel == 1 && (
                <PanelGroup accordion bordered defaultActiveKey={1}>
                  <Panel
                    header="Kad ne znam kud bi s nekom opcijom, tu je"
                    eventKey={1}
                  >
                    {
                      <div style={{ padding: 5 }}>
                        <IconButton
                          size="lg"
                          icon={
                            <Icon
                              as={FaSvgIcon}
                              faIcon={
                                context?.settings?.misc?.gameErrorPopups
                                  ? faCheck
                                  : faSquare
                              }
                            />
                          }
                          appearance={
                            context?.settings?.misc?.gameErrorPopups
                              ? "primary"
                              : "default"
                          }
                          onClick={() =>
                            socket.emit("setSetting", {
                              gameErrorPopups:
                                !context?.settings?.misc?.gameErrorPopups,
                            })
                          }
                        >
                          Greske/Upozorenja/Popup za vrijeme igre
                        </IconButton>
                      </div>
                    }
                    <Checkbox
                      checked={context?.settings?.misc?.help}
                      onChange={() => {
                        context.setSettings({
                          ...context.settings,
                          misc: {
                            ...context.settings.misc,
                            help: !context?.settings?.misc?.help,
                          },
                        });
                        socket.emit("setSetting", {
                          help: !context?.settings?.misc?.help,
                        });
                      }}
                    >
                      {" "}
                      Upute/Pomoć
                    </Checkbox>
                  </Panel>
                  <Panel header="Zvuk" eventKey={2}>
                    {sounds.allSounds.map((sound, s) => {
                      return (
                        <div style={{ padding: 5 }}>
                          <IconButton
                            icon={<Icon as={FaSvgIcon} faIcon={faPlay} />}
                            placement="right"
                            onClick={() => sounds.playSpecific(s)}
                          >
                            {sound}
                          </IconButton>
                          {context?.settings?.sounds?.unlocked?.indexOf(s) ==
                          -1 ? (
                            <IconButton
                              size="lg"
                              icon={<Icon as={FaSvgIcon} faIcon={faLock} />}
                              color="orange"
                              appearance="primary"
                              onClick={() =>
                                setYesNo({
                                  title: "Kupovina",
                                  description:
                                    "Želite li otključati ovaj zvuk za 100 dukata?",
                                  yes: "da",
                                  no: "ne",
                                  opened: true,
                                  yesCb: () => {
                                    socket.emit("shopBuy", { sound: s });
                                  },
                                })
                              }
                            >
                              Otključaj
                            </IconButton>
                          ) : (
                            <IconButton
                              size="xs"
                              icon={
                                <Icon
                                  as={FaSvgIcon}
                                  faIcon={s != chatSound ? faSquare : faCheck}
                                />
                              }
                              appearance={
                                s != chatSound ? "default" : "primary"
                              }
                              onClick={() => socket.emit("setChatSound", s)}
                            />
                          )}
                        </div>
                      );
                    })}
                  </Panel>
                  {
                    <Panel
                      header="Blok Lista"
                      eventKey={3}
                      onClick={() => socket.emit("getBlockList")}
                    >
                      {blockedUsersDetails.map((user, s) => {
                        return (
                          <div
                            style={{
                              padding: 5,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <Avatar src={user.picture} />
                              <p>{user.name}</p>
                            </div>
                            <IconButton
                              icon={<Icon as={FaSvgIcon} faIcon={faTrash} />}
                              placement="left"
                              color="orange"
                              onClick={() => unblock(user)}
                            >
                              Skini s block liste za 10 dukata
                            </IconButton>
                          </div>
                        );
                      })}
                    </Panel>
                  }
                  {/*<Panel header="Figure" eventKey={2}>
                figure
              </Panel>
              <Panel header="Ostalo" eventKey={3}>
                ostalo
              </Panel>*/}
                </PanelGroup>
              )}
              {currentPanel == 2 && (
                <div>
                  <PanelGroup accordion bordered defaultActiveKey={0}>
                    <Panel header="Figure" eventKey={1}>
                      {[
                        "metla",
                        "terminator",
                        "devil",
                        "sheriff",
                        "jason",
                        "ghandi",
                        "vrisak",
                        "mraz",
                        "horns",
                        "kiklop",
                        "kiklopBig",
                        "krak",
                        "ktulu",
                        "unicornSkull",
                        "witch",
                      ].map((figure, s) => {
                        return (
                          <div style={{ padding: 5 }}>
                            <img
                              src={
                                "/assets/Lik_" +
                                figure +
                                "_" +
                                ["crveni", "plavi", "zuti", "zeleni"][0] +
                                ".png"
                              }
                            />
                            <img
                              src={
                                "/assets/Lik_" +
                                figure +
                                "_" +
                                ["crveni", "plavi", "zuti", "zeleni"][1] +
                                ".png"
                              }
                            />
                            <img
                              src={
                                "/assets/Lik_" +
                                figure +
                                "_" +
                                ["crveni", "plavi", "zuti", "zeleni"][2] +
                                ".png"
                              }
                            />
                            <img
                              src={
                                "/assets/Lik_" +
                                figure +
                                "_" +
                                ["crveni", "plavi", "zuti", "zeleni"][3] +
                                ".png"
                              }
                            />
                            {context.user.availableFigures.indexOf(s) == -1 ? (
                              <IconButton
                                size="lg"
                                icon={<Icon as={FaSvgIcon} faIcon={faLock} />}
                                appearance="primary"
                                color="orange"
                                onClick={() =>
                                  setYesNo({
                                    title: "Kupovina",
                                    description: `Želite li otključati figuru za ${1000 * s} dukata?`,
                                    yes: "da",
                                    no: "ne",
                                    opened: true,
                                    yesCb: () => {
                                      socket.emit("shopBuy", { figure: s });
                                    },
                                  })
                                }
                              >
                                Otključaj
                              </IconButton>
                            ) : (
                              "Imate ovu figuru"
                            )}
                          </div>
                        );
                      })}
                    </Panel>
                    <Panel header="Svasta" eventKey={2}>
                      {[
                        {
                          title: "Proširi blok listu za još 5 korisnika",
                          description: `Blok lista vam je trenutno na ${context.settings.blockedUsers.length} od ${context.settings.blockLimit}`,
                          price: 500,
                          func: () => {
                            socket.emit("shopBuy", { misc: 1 });
                          },
                        },
                        {
                          title: "Ukidanje Zabrane",
                          description:
                            "Cijena ukidanja zabrane ce ovisiti koliko vi sami vrijedite igri, igrači koji su česće na igri, gledaju reklame ili plaćaju će imati vise privilegija za razliku od recimo laznih profila s kojima se dolazi isključivo za vrijeđanje drugih igrača",
                          price: 0,
                        },
                        {
                          title: "Kamere",
                          description:
                            "Ako vam je ovo aktivirano, vi i svaka osoba s kojom razgovarate ili igrate ima opciju upaliti kameru",
                          price: 10000,
                        },
                        {
                          title: "Promjena Imena",
                          description: "Promijenite si ime na igri",
                          price: 2500,
                          func: () => {
                            console.log("promjeni ime u", newName);
                            if (newName.length) {
                              socket.emit("shopBuy", { newName });
                            }
                          },
                        },
                      ].map((item, s) => {
                        return (
                          <div>
                            <div
                              style={{
                                padding: 5,
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div style={{ maxWidth: 250 }}>
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                              </div>
                              <div>
                                <IconButton
                                  size="lg"
                                  icon={<Icon as={FaSvgIcon} faIcon={faLock} />}
                                  color={item.func ? "orange" : undefined}
                                  onClick={() =>
                                    item.func
                                      ? item.func()
                                      : console.log("not implemented yet!")
                                  }
                                >
                                  Kupi za {item.price} dukata
                                </IconButton>
                                {s == 3 && (
                                  <Input
                                    placeholder="upiši novo ime"
                                    onChange={setNewName}
                                  />
                                )}
                              </div>
                            </div>
                            <Divider />
                          </div>
                        );
                      })}
                    </Panel>
                    {/*<Panel header="Figure" eventKey={2}>
                  figure
                </Panel>
                <Panel header="Ostalo" eventKey={3}>
                  ostalo
                </Panel>*/}
                  </PanelGroup>
                </div>
              )}
              {currentPanel == 3 && <div></div>}
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  setOpened(false);
                  setError(0);
                }}
                appearance="primary"
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            open={yesNo.opened}
            onClose={() => {
              setYesNo({ opened: false });
            }}
            size={props.mini ? "xs" : "md"}
          >
            <Modal.Header>
              <Modal.Title>{yesNo.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{yesNo.description}</Modal.Body>
            <Modal.Footer>
              {yesNo.yes && (
                <Button
                  onClick={() => {
                    if (yesNo.yesCb) yesNo.yesCb();
                    setYesNo({ opened: false });
                  }}
                  appearance="primary"
                >
                  {yesNo.yes}
                </Button>
              )}
              {yesNo.no && (
                <Button
                  onClick={() => {
                    if (yesNo.noCb) yesNo.noCb();
                    setYesNo({ opened: false });
                  }}
                  color="red"
                >
                  {yesNo.no}
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </CentralContext.Consumer>
  );
};

export default Settings;

import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Form,
  Input,
  Radio,
  RadioGroup,
  Steps,
  Toggle,
} from "rsuite";
import socket from "../../socket";
import InviteList from "../PlayerList/inviteList";
import ColorPicker from "./colorPicker";
import FigurePicker from "./figurePicker";
import styles from "./index.module.css";

const NewRoom = (props) => {
  const [roomName, setRoomName] = useState("");
  const [roomPassword, setRoomPassword] = useState("");
  const [roomType, setRoomType] = useState(1);
  const [pickedColors, setPickedColors] = useState([]);
  const [pickedColor, setPickedColor] = useState(false);
  const [creationStep, setCreationStep] = useState(0);
  const roomTypes = ["Chat", "Klasična", "Smrtonosna", "Dvorci"];

  const colorPick = (color) => {
    socket.emit("setPlayerColor", color);
  };

  const startGame = () => {
    socket.emit("startGame");
  };

  useEffect(() => {
    socket.setupListener("colorPicked", (colors) => {
      //console.log("picked colors", colors);
      setPickedColors(colors);
    });
  }, []);

  return (
    <div style={{ backgroundColor: "#FFFFFFEE", padding: 10 }}>
      {!props.popup && (
        <div>
          <Steps current={creationStep}>
            <Steps.Item title="Naziv Sobe" />
            <Steps.Item title="Tip Igre" />
            <Steps.Item title="Stvaranje" />
          </Steps>
          <Form layout="horizontal" style={{ padding: 10 }}>
            {creationStep == 0 && (
              <Form.Group>
                <Form.ControlLabel>Ime Sobe</Form.ControlLabel>
                <Form.Control
                  name="roomName"
                  onChange={setRoomName}
                  value={roomName}
                  placeholder="Upisite ime sobe"
                />
              </Form.Group>
            )}
            {creationStep == 1 && (
              <Form.Group visible={creationStep == 1}>
                <Form.ControlLabel>Tip Sobe</Form.ControlLabel>
                <RadioGroup
                  inline
                  name="radioList"
                  value={roomType}
                  onChange={setRoomType}
                >
                  <Radio value={1}>Klasicna</Radio>
                  <Radio value={2}>Smrtonosna</Radio>
                  {/*<Radio value={3}>Dvorci</Radio>*/}
                  {/*<Radio value={3}>Chat</Radio>
                <Radio value={4} disabled>
                  Premium
                </Radio>*/}
                </RadioGroup>
              </Form.Group>
            )}
            {creationStep == 2 && (
              <div>
                <p>Stvarate sobu naziva: {roomName}</p>
                <p>Tip sobe: {roomTypes[roomType]}</p>
              </div>
            )}
            {/*<Form.Group>
              <Form.ControlLabel>Lozinka</Form.ControlLabel>
              <Form.Control name="password" onChange={setRoomPassword} value={roomPassword} />
            </Form.Group>*/}
            <Form.Group>
              <ButtonToolbar>
                {creationStep < 2 && (
                  <Button
                    onClick={() => setCreationStep(creationStep + 1)}
                    disabled={roomName.length == 0}
                    appearance="primary"
                  >
                    Dalje
                  </Button>
                )}
                {creationStep == 2 && (
                  <Button
                    onClick={() =>
                      props.createRoom({ roomName, roomType, roomPassword })
                    }
                    appearance="primary"
                  >
                    Napravi Sobu
                  </Button>
                )}
                <Button onClick={props.cancelRoomOptions}>Odustani</Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </div>
      )}
      {props.popup == 1 && (
        <div className={styles.centerEverything}>
          <p>
            Pozovite prijatelje ili pričekajte da netko uđe u vašu sobu koja je
            sada na popisu svima u predsoblju.
          </p>
          <InviteList mobile={props.mobile} />
          <Button
            onClick={() => {
              socket.emit("inviteList");
            }}
            appearance="primary"
          >
            Osvježi popis
          </Button>
          <div className={styles.center} style={{ marginTop: 20 }}>
            <p>Izaberite boju figura s kojima želite igrati:</p>
            <ColorPicker colorPick={colorPick} update={props.update} />
          </div>
          {roomType != 3 && (
            <div className={styles.center} style={{ marginTop: 20 }}>
              <p>Namjestite izgled figura:</p>
            </div>
          )}
          {roomType != 3 && (
            <FigurePicker update={props.update} style={{ marginTop: 20 }} />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: 20,
            }}
          >
            <Button appearance="primary" onClick={startGame}>
              Pokreni
            </Button>
            <Button onClick={props.exitRoom} color="red">
              Odustani
            </Button>
          </div>
        </div>
      )}
      {props.popup == 2 && (
        <div className={styles.centerEverything}>
          <p>Pričekajte da vlasnik sobe pokrene igru</p>
          <p>
            Pozovite prijatelje ili pričekajte da netko uđe u vašu sobu koja je
            sada na popisu svima u predsoblju.
          </p>
          <InviteList mobile={props.mobile} />
          <Button
            onClick={() => {
              socket.emit("inviteList");
            }}
            appearance="primary"
          >
            Osvježi popis
          </Button>
          <p>Izaberite jednu od slobodnih boja:</p>
          <div className={styles.center}>
            <ColorPicker colorPick={colorPick} update={props.update} />
          </div>
          {roomType != 3 && (
            <div className={styles.center}>
              <p>Namjestite izgled figura:</p>
            </div>
          )}
          {roomType != 3 && <FigurePicker update={props.update} />}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: 20,
            }}
          >
            <Button appearance="primary" onClick={startGame}>
              Pokreni
            </Button>
            <Button onClick={props.exitRoom} color="red">
              Odustani
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewRoom;

import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@rsuite/icons";
import React, { useContext, useEffect, useState } from "react";
import { IconButton } from "rsuite";
import { CentralContext } from "../../context";
import socket from "../../socket";

const colors = ["red", "blue", "yellow", "green"];
const types = [
  "metla",
  "terminator",
  "devil",
  "sheriff",
  "jason",
  "ghandi",
  "vrisak",
  "mraz",
  "horns",
  "kiklop",
  "kiklopBig",
  "krak",
  "ktulu",
  "unicornSkull",
  "witch",
  "bat",
  "eye",
  "pimpReaper",
  "snail",
];
const colorToHr = (color) => {
  switch (color) {
    case 0:
    case "red":
      return "crveni";
    case 1:
    case "blue":
      return "plavi";
    case 2:
    case "yellow":
      return "zuti";
    case 3:
    case "green":
      return "zeleni";
      break;
    default:
  }
};

const FigurePick = (props) => {
  //console.log("props.types", props.types, "fig", props.fig);
  const [figure, setFigure] = useState(
    props.availableFigures.indexOf(props.types[props.fig]) || 0,
  );
  const [fig, setFig] = useState(
    "Lik_" +
      types[props.availableFigures[figure]] +
      "_" +
      colorToHr(props.color) +
      ".png",
  );

  const previousFigure = () => {
    if (figure > 0) {
      setFigure(figure - 1);
    }
  };
  const nextFigure = () => {
    if (figure < props.availableFigures.length - 1) {
      setFigure(figure + 1);
    }
  };

  useEffect(() => {
    setFig(
      "Lik_" +
        types[props.availableFigures[figure]] +
        "_" +
        colorToHr(props.color) +
        ".png",
    );
    socket.emit("setFigureType", {
      fig: props.fig,
      type: props.availableFigures[figure],
    });
  }, [figure, props.color]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <img src={`/assets/${fig}`} style={{ height: 100, width: 50 }} />
      <div>
        <IconButton
          icon={<FontAwesomeIcon icon={faAngleLeft} />}
          disabled={figure == 0}
          appearance={figure ? "primary" : "default"}
          onClick={previousFigure}
        />
        <IconButton
          icon={<FontAwesomeIcon icon={faAngleRight} />}
          disabled={figure == props.availableFigures.length - 1}
          appearance={figure < 7 ? "primary" : "default"}
          onClick={nextFigure}
        />
      </div>
    </div>
  );
};

const FigurePicker = (props) => {
  const cntx = useContext(CentralContext);
  const [color, setColor] = useState(false);
  const [availableFigures, setAvailableFigures] = useState([0]);

  useEffect(() => {
    console.log("props.update", props.update);
    if (props.update.players) {
      for (let player in props.update.players) {
        console.log(player, props.update.players[player].id, cntx.user);
        if (
          props.update.players[player].id == cntx.user.id &&
          props.update.players[player].unset == false
        ) {
          setColor(player);
          cntx.setTypes(props.update.players[player].setFigures);
          setAvailableFigures(props.update.players[player].availableFigures);
        }
      }
    }
  }, [props.update]);

  return (
    <CentralContext.Consumer>
      {(context) => (
        <div>
          {JSON.stringify(color)}
          {color && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <FigurePick
                color={color}
                types={cntx.types}
                availableFigures={availableFigures}
                fig={0}
              />
              <FigurePick
                color={color}
                types={cntx.types}
                availableFigures={availableFigures}
                fig={1}
              />
              <FigurePick
                color={color}
                types={cntx.types}
                availableFigures={availableFigures}
                fig={2}
              />
              <FigurePick
                color={color}
                types={cntx.types}
                availableFigures={availableFigures}
                fig={3}
              />
            </div>
          )}
        </div>
      )}
    </CentralContext.Consumer>
  );
};

export default FigurePicker;

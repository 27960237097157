import React, {useState, useEffect} from 'react';
import {

} from 'rsuite';
import {CentralContext} from "../../context";

const Inventory = props => {
  const items = {
    "chocolate": "🍫",
    "rose": "🌹",
    "diamond": "💎"
  }
  return (
    <CentralContext.Consumer>
    {(context)=>(
      <div style={{display: "flex", alignItems:"baseline", flexDirection:"row-reverse"}}>
      {!!context?.user?.inventory?.gifts && Object.entries(context.user.inventory.gifts).map(item=>{
        return (<p style={{fontSize:"x-large", margin:"0 5px"}}>{items[item[0]]}x{item[1]}</p>)
      })}
      {!(context?.user?.inventory?.gifts) && <p></p>}
      </div>
    )}
    </CentralContext.Consumer>
  )
}

export default Inventory;

import React, { useState, useEffect, useRef } from 'react';
import { Group, Circle } from 'react-konva';

import Bg from "./bg";
import Dice from "./dice";
import Home from "./home";
import TurnIndicator from "./turnIndicator";
import Fields from "./fields";
import Figure from "./figure";

const width=500;
const height=500;
const redHomeCoords = [{x:50, y:138},{x:85, y:125},{x:113, y:102},{x:135, y:70}];
const blueHomeCoords = [{x:width-50, y:138},{x:width-85, y:125},{x:width-113, y:102},{x:width-135, y:70}];
const yellowHomeCoords = [{x:width-50, y:height-138},{x:width-85, y:height-125},{x:width-113, y:height-102},{x:width-135, y:height-70}];
const greenHomeCoords = [{x:50, y:height-138},{x:85, y:height-125},{x:113, y:height-102},{x:135, y:height-70}];

const Board = props => {
  const [turnColor, setTurnColor] = useState("blue");
  const [diceNum, setDiceNum] = useState(6);
  const [fields, setFields] = useState([]);
  const [gameType, setGameType] = useState(0);
  const [muerte, setMuerte] = useState(false);
  const [muerteOut, setMuerteOut] = useState(0);
  const [update, setUpdate] = useState([]);
  const DEBUG=false;

  const getHomeCoords = (color, position) => {
    //if(DEBUG)console.log(color, position);
    switch (color) {
      case "red":
        return redHomeCoords[position];
      case "blue":
        return blueHomeCoords[position];
      case "yellow":
        return yellowHomeCoords[position];
      case "green":
        return greenHomeCoords[position];
    }
  }

  const getDestination = (fieldIndex, color, position) => {
    if(DEBUG)console.log("get field with index", fieldIndex, color, position);
    if(fieldIndex==-1 && color){
      return getHomeCoords(color, position);
    } else if(fields && fields.length && fields[fieldIndex]){
      if(DEBUG)console.log("field:", fields[fieldIndex]);
      return fields[fieldIndex];
    }
  }

  useEffect(()=>{

  }, [gameType]);

  useEffect(()=>{
    if(DEBUG)console.log("update in board", props.update);
    if(props?.update?.gameType){
      if(DEBUG)console.log("setting game type", props.update.gameType);
      setGameType(props.update.gameType);
    }
    if(props?.update?.muerte){
      setMuerte(true);
    }

    if(props?.update?.muerte === false){
      setMuerte(false);
    }
    if(props?.update?.muerteOut){
      setMuerteOut(props.update.muerteOut);
    }

    setUpdate(props.update);
  }, [props.update])


  const rendFigures = ()=> {
    let result = [];
    for( const color in props.update.players){
      for( const fig in props.update.players[color].figures){
        result.push(<Figure onClick={()=>props.socket.emit("move", {color:color, id: fig})} color={color} type={props.update.players[color].figures[fig].type} key={color+"_"+fig} fig={fig} figId={color+"_"+fig} onField={props.update.players[color].figures[fig].onField} goto={getDestination} update={update} fields={fields} onTapWorkAround={()=>props.socket.emit("move", {color:color, id: fig})}/>)
      }
    }
    return result;
  }

  return (
    <Group>
      <Bg/>
      <TurnIndicator update={update}/>
      <Group>
      {
        ["red", "blue", "yellow", "green"].map(homeColor=>{
          return <Home color={homeColor} update={update} key={homeColor}/>
        })
      }
      </Group>
      <Group>
        <Fields updateParent={setFields} gameType={gameType} muerte={muerte} muerteOut={muerteOut}/>
      </Group>
      <Group>
        {
          rendFigures().map((figure, i)=>{
            return figure;
          })
        }
      </Group>
      <Dice update={update} onClick={(e)=>{props.socket.emit("throwDice")}} socket={props.socket}/>
    </Group>
  )
}

export default Board;

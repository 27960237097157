import loaderSvc from "../loaderSvc";

const createjs = window.createjs;
const ogScale = 0.37;

function Figure(obj, move) {
  this.moveMe = move;
  this.figure = new createjs.Bitmap(
    loaderSvc.getResult(this.getFigureSpriteId(obj)),
  );
  this.color = obj.color;
  this.id = obj.id;
  this.figure.scaleX = this.figure.scaleY = ogScale;
  this.correctionX =
    -(this.figure.getBounds().width * this.figure.scaleX) / 2.5;
  this.correctionY =
    -(this.figure.getBounds().height * this.figure.scaleY) / 1.1;
  this.figure.x = obj.homeLocation.x + this.correctionX;
  this.figure.y = obj.homeLocation.y + this.correctionY;
  this.figure.on("click", this.moveMe.bind(this));
  this.homeLocation = obj.homeLocation;
}

const pt = Figure.prototype;

pt.getFigureSpriteId = (obj) => {
  let id = "";
  const colors = ["red", "blue", "yellow", "green"];
  const types = [
    "broom",
    "terminator",
    "devil",
    "sheriff",
    "jason",
    "ghandi",
    "screem",
    "santa",
  ];
  return colors[obj.color] + "_" + types[obj.type];
};

pt.addToStage = function (stage) {
  stage.addChild(this.figure);
};

pt.removeFromStage = function (stage) {
  stage.removeChild(this.figure);
};

pt.moveMe = function (e) {
  this.move(this.id);
};

pt.getInstance = function () {
  return this.figure;
};

pt.getCorrection = function () {
  return {
    x: this.correctionX,
    y: this.correctionY,
  };
};

pt.getWidth = function () {
  return this.figure.width * this.figure.scaleX;
};

pt.getX = function () {
  return this.figure.x;
};

pt.setX = function (val) {
  this.figure.x = val;
};

pt.getY = function () {
  return this.figure.y;
};

pt.setY = function (val) {
  this.figure.y = val;
};

pt.getHomeCoords = function () {
  return this.homeLocation;
};

pt.resetToHome = function () {
  this.figure.x = this.homeLocation.x + this.correctionX;
  this.figure.y = this.homeLocation.y + this.correctionY;
};

pt.die = function () {
  this.figure.visible = false;
};
pt.resurect = function () {
  this.figure.visible = true;
};
pt.setScale = function (scale) {
  this.figure.scale = ogScale * scale.x;
  this.correctionX =
    -(this.figure.getBounds().width * this.figure.scaleX) / 2.5;
  this.correctionY =
    -(this.figure.getBounds().height * this.figure.scaleY) / 1.1;
  this.resetToHome();
};

export default Figure;

import React, { useContext, useEffect, useState } from "react";
import "react-chat-elements/dist/main.css";
import CookieConsent from "react-cookie-consent";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// import "rsuite/dist/styles/rsuite-default.css";
import "rsuite/dist/rsuite.min.css";
import "./App.css";
import Game from "./components/GameK";
import { CentralContext } from "./context";
import Pages from "./pages";
import Admin from "./pages/admin";
import AdRewards from "./pages/adRewards";
import BannedPage from "./pages/banned";
import Help from "./pages/help";
import ProfilePage from "./pages/profile";
import StatusPages from "./pages/status";
import TAC from "./pages/ToS";
import TVPage, { TVNew } from "./pages/tv";
import fbsdkLoader from "./services/fbsdkLoader";
import gdsdkLoader from "./services/gdsdkLoader";
import gmsdkLoader from "./services/gmsdkLoader";
import rewardsService from "./services/rewards.service";
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

const App = () => {
  const [showAd, setShowAd] = useState(false);
  const [fbLoaded, setFbLoaded] = useState(false);
  const [showError, setShowError] = useState(false);
  const cntx = useContext(CentralContext);
  const backgroundImage = "/images/pattern3.jpg";

  const initGDsdk = () => {
    window["GD_OPTIONS"] = {
      gameId: "7eba1bb3f04c4f0aaae9e628ffb3d628",
      onEvent: function (event) {
        switch (event.name) {
          case "SDK_GAME_START":
            // advertisement done, resume game logic and unmute audio
            setShowAd(false);
            break;
          case "SDK_GAME_PAUSE":
            // pause game logic / mute audio
            setShowAd(true);
            break;
          case "SDK_GDPR_TRACKING":
            // this event is triggered when your user doesn't want to be tracked
            break;
          case "SDK_GDPR_TARGETING":
            // this event is triggered when your user doesn't want personalised targeting of ads and such
            break;
          case "CLICK":
          case "COMPLETE":
          case "ALL_ADS_COMPLETED":
            console.log("maybe give more?");
            break;
          case "IMPRESSION":
            // this event is triggered when your user doesn't want personalised targeting of ads and such
            console.log("ad watched");
            rewardsService
              .impression()
              .then(() => {
                console.log("nice");
              })
              .catch((e) => {
                console.error(e);
              });
            break;
          case "AD_ERROR":
            console.error(event);
            break;
          default:
            console.log("unhandled event", event.name);
        }
      },
    };
    console.log("gd options set", window["GD_OPTIONS"]);
    if (
      window.location.hostname != "localhost" &&
      document.referrer != "https://apps.facebook.com/" &&
      window.location.hostname != "staging.cnljs.com" &&
      window.location.hostname != "cnljs.com"
    ) {
      gdsdkLoader(true);
    } else console.log("window.location.hostname", window.location.hostname);
  };
  const initGMsdk = () => {
    window.SDK_OPTIONS = {
      gameId: "ee7g7byxp871y8we1pybk5q7a5dnnxx1",
      onEvent: function (a) {
        switch (a.name) {
          case "SDK_GAME_PAUSE":
            // pause game logic / mute audio
            setShowAd(true);
            break;
          case "SDK_GAME_START":
            // advertisement done, resume game logic and unmute audio
            setShowAd(false);
            break;
          case "SDK_READY":
            // when sdk is ready
            break;
          default:
            console.log("unhandled event", a.name);
        }
      },
    };
    console.log("gm options set", window.SDK_OPTIONS);
    if (
      window.location.hostname != "localhost" &&
      document.referrer != "https://apps.facebook.com/" &&
      window.location.hostname != "staging.cnljs.com"
    ) {
      gmsdkLoader(true);
    }
  };

  useEffect(() => {
    //initGMsdk();
    console.log(
      "current domain",
      window.location.hostname,
      "referrer",
      document.referrer,
    );
    initGDsdk();
    // if (process.env.REACT_APP_NODE_ENV === "production") {
    fbsdkLoader(true, (e) => {
      if (e && e.error) {
        console.log("error while loading fb sdk", "ctx.e", cntx.setErrors);
        setShowError(true);
        if (cntx && cntx.setErrors) {
          cntx.setErrors((previousErrors) => {
            let newErrors = [...previousErrors];
            newErrors.push({
              error: "fbsdk",
              msg: "probably social tracking issue",
            });
          });
        } else console.log("cntx undefined");
      } else {
        initFB();
      }
    });
    // }
  }, []);

  // useEffect(() => {
  //   if (!cntx.visitorId) {
  //     FingerprintJS.load({
  //       token: 'czmJRNHe3ulP5LzhKLFn',
  //       region: 'eu',
  //       endpoint: 'https://fp.cnljs.com',
  //     })
  //       .then((fp) => fp.get())
  //       .then((result) => {
  //         cntx.setVisitorId(result.visitorId);
  //       });
  //   }
  // }, []);

  const initFB = async () => {
    console.log("fb sdk loaded", process.env.REACT_APP_FB_APP_ID);
    if (window.FB) {
      setFbLoaded(true);
      console.log("fb init");
      try {
        window.FB.init({
          appId: `${process.env.REACT_APP_FB_APP_ID}`,
          cookie: true,
          //status: true,
          xfbml: true,
          version: "v14.0",
        });
      } catch (e) {
        console.error("error while initing fb");
      }
    } else {
      console.log("but obviously blocked");
      // cntx.setErrors([
      //   {
      //     error: "fbsdk",
      //     msg: "probably social tracking issue (2)",
      //   },
      // ]);
    }
  };

  return (
    <CentralContext.Consumer>
      {(context) => (
        <div
          className="rootSite"
          style={
            isBrowser
              ? {
                  width: showAd || !fbLoaded ? "100vw" : 927,
                  height: showAd || !fbLoaded ? "100vh" : 927,
                  minHeight: 700,
                  position: "bottom",
                }
              : { position: "bottom", width: "100%", height: "100%" }
          }
        >
          <Router>
            {/*<Game/>*/}
            <Routes>
              <Route path="/admin" Component={Admin} />
              <Route path="/ads" Component={AdRewards} />
              <Route component={BannedPage} exact path="/banned" />
              <Route Component={TAC} path="/TAC" />
              <Route exact path="/profile/:dbid" component={ProfilePage} />
              <Route exact path="/status/:type" component={StatusPages} />
              <Route path="/tv" Component={TVNew} />
              <Route path="/tv/:roomId" Component={TVPage} />
              <Route path="/" Component={Pages} />
            </Routes>
          </Router>
          <CookieConsent location="bottom" buttonText="Slažem se">
            <p>
              Covjece ne ljuti se koristi standardne tzv. "Cookie" odnosno
              kolačiće kako bi sve funkcije na stranicama bile dostupne i
              funkcionalne. Međutim sukladno europskim propisima, dužni smo vas
              izvijestiti o primjeni kolačića, kao i dobiti vaš pristanak za
              primjenu istih.{" "}
            </p>
            <span style={{ fontSize: "10px" }}>
              Da bi maknuli ovu poruku kliknite na gumb "Slažem se"
            </span>
          </CookieConsent>
        </div>
      )}
    </CentralContext.Consumer>
  );
};

export default App;

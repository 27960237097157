export const FaSvgIcon = ({ faIcon, ...rest }) => {
  // console.log("faicon", faIcon);
  const width = faIcon.icon[0];
  const height = faIcon.icon[1];
  const path = faIcon.icon[4];
  return (
    <svg {...rest} width="2em" height="2em" viewBox={`0 0 ${width} ${height}`}>
      <path fill="currentColor" d={path}></path>
    </svg>
  );
};

export const DefaultRoomUpdate = {
  gameType: 0,
  muerte: false,
  muerteOut: 0,
  turn: 1,
  dice: 1,
  players: {
    red: {
      figures: {
        0: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        1: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        2: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        3: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
      },
      name: "",
      picture: "",
      id: 0,
    },
    blue: {
      figures: {
        0: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        1: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        2: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        3: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
      },
      name: "",
      picture: "",
      id: 0,
    },
    yellow: {
      figures: {
        0: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        1: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        2: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        3: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
      },
      name: "",
      picture: "",
      id: 0,
    },
    green: {
      figures: {
        0: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        1: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        2: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        3: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
      },
      name: "",
      picture: "",
      id: 0,
    },
  },
};

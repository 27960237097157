import React, {useState} from 'react';

export const CentralContext = React.createContext();

const defaultUserValues = {
  user: false,
  color: "none",
  errors: [1,2,3]
}

const defaultSettings = {
  sounds: {
    mute: false,
    chatSound: 0,
    unlocked: [0,4]
  },
  misc: {
    gameErrorPopups: true,
    help: true
  },
  blockedList: [],
  blockedLimit: 5
}

const CentralStore = props => {
  const [user, setUser] = useLocalStorage("user", defaultUserValues);
  const [color, setColor] = useState("none");
  const [errors, setErrors] = useState([]);
  const [visitorId, setVisitorId] = useLocalStorage("fp", false);
  const [chats, setChats] = useState([{
    id: 0,
    channel: {
      name: "Predsoblje"
    },
    msgs: []
  }]);
  const [currentChat, setCurrentChat] = useState(0);
  const [unreadChats, setUnreadChats] = useState(0);
  const [playerCount, setPlayerCount] = useState(0);
  const [mobileView, setMobileView] = useState(2);
  const [roomListFilter, setRoomListFilter] = useState(0);
  const [playlist, setPlaylist] = useState([]);
  const [settings, setSettings] = useLocalStorage(process.env.REACT_APP_SETTINGS_KEY, defaultSettings);
  const [types, setTypes] = useState(user.setFigures);

  return (
    <CentralContext.Provider value = {{
      user, setUser,
      color, setColor,
      errors, setErrors,
      settings, setSettings,
      chats, setChats,
      unreadChats, setUnreadChats,
      playerCount, setPlayerCount,
      mobileView, setMobileView,
      currentChat, setCurrentChat,
      roomListFilter, setRoomListFilter,
      playlist, setPlaylist,
      types, setTypes,
      visitorId, setVisitorId
    }}>
      {props.children}
    </CentralContext.Provider>
  )
}

// Hook

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      window.localStorage.removeItem(key);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export default CentralStore;

import React, { useState, useContext, useEffect } from 'react';
import { CentralContext } from "../context";

const Help = props => {
  const cntx = useContext(CentralContext);

  return (
    <CentralContext.Consumer>
    {(context)=>(
      <div>
        <p>1. Social Tracking blokiranje</p>
        <p>{context.errors && context.errors.map(err=>{
          if(err.error=="fbsdk"){
            return <div>
              <p>kliknite na ikonu stita, lijevo od mjesta gdje inace upisujete ime stranice i kliknite na OFF ako vam je ukljuceno "Enhanced tracking protection" ili na nasem neki vjerojatno uzasan prijevod u vezi pracenja</p>
              <img src="/socialtracking.png"/>
            </div>
          }
          return <p>{JSON.stringify(err)}</p>
        })}</p>
        {!context.errors && <div>
          <p>Ako imate problema s ulaskom na igru, provjerite da li su dozvoljeni "popup" prozori, obicno je zuta traka na vrhu sa opcijama desno. ako nije to, mozda ste u incoginto/private modu, vidite da niste ukljucili blokiranje drustvenih mreza na drugim stranicama, dok ne smislim bolje objasnjenje morat ce i ovo bit dobro, snadjite se za sada :) </p>
          <img src="/socialtracking.png" width="400px" />
          <p>{JSON.stringify(context)}</p>
        </div>}
      </div>
    )}
    </CentralContext.Consumer>
  )
}

export default Help;

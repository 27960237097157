export function handleResponse(response) {
  return response.text().then((text) => {
    //console.log(text);
    const data = text && text.indexOf("Error") == -1 && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        //authenticationService.logout();
        //window.location.reload(true);
      } else if ([400].indexOf(response.status) !== -1) {
        //authenticationService.logout();
      }

      const error = (data && data.message) || response.statusText;
      console.log("rejecting with", error);
      return Promise.reject(error);
    } else {
      // rewards
      console.log("response ok");
    }

    return Promise.resolve(data);
  });
}

export default handleResponse;

import handleResponse from './handleResponse';

const userService = {
  getComments,
  newComment,
  like,
  sendFbAuth,
  test,
  getLargePicture,
  getPicture,
  getMe,
  getUserWithId
};

function test() {
  const requestOptions = { method: 'GET', credentials: 'include' };
  return fetch(`${process.env.REACT_APP_ROOT}/api/`, requestOptions).then(handleResponse);
}

function getLargePicture(id){
  const requestOptions = { method: 'GET', credentials: 'include' };
  return fetch(`${process.env.REACT_APP_ROOT}/api/user/picture/${id}`, requestOptions).then(handleResponse);
}

function getPicture(id){
  const requestOptions = { method: 'GET', credentials: 'include' };
  return fetch(`${process.env.REACT_APP_ROOT}/api/user/pic/${id}`, requestOptions).then(handleResponse);
}

function getMe(){
  const requestOptions = { method: 'GET', credentials: 'include' };
  return fetch(`${process.env.REACT_APP_ROOT}/api/user/me`, requestOptions).then(handleResponse);
}

function getUserWithId(id){
  const requestOptions = { method: 'GET', credentials: 'include' };
  return fetch(`${process.env.REACT_APP_ROOT}/api/user/withId/${id}`, requestOptions).then(handleResponse);
}

function getComments(category, id) {
    const requestOptions = { method: 'GET' };
    return fetch(`${process.env.REACT_APP_ROOT}/api/comments/${category}/${id}`, requestOptions).then(handleResponse);
}

function sendFbAuth(data){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: (data.authResponse)?JSON.stringify(data.authResponse):JSON.stringify(data)
  };

  return fetch(`${process.env.REACT_APP_ROOT}/api/user/fbData`, requestOptions)
  .then(handleResponse)
  .catch(e=>{
    console.log("should return error", e);
    return Promise.reject(e);
  });
}

function newComment(data) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(data)
  };

  return fetch(`${process.env.REACT_APP_ROOT}/api/comments/new`, requestOptions)
    .then(handleResponse)
    .catch(e=>{
      console.log("should return error", e);
      return Promise.reject(e);
    });
}

function like(id) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({commentId:id})
  };

  return fetch(`${process.env.REACT_APP_ROOT}/api/comments/like`, requestOptions)
    .then(handleResponse)
    .catch(e=>{
      console.log("should return error", e);
      return Promise.reject(e);
    });
}

export default userService

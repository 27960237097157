import { Icon, Peoples, PlayOutline, Plus as PlusIcon } from "@rsuite/icons";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  FlexboxGrid,
  IconButton,
  List,
} from "rsuite";
import { CentralContext } from "../../context";
import { screenSize } from "../../hooks";
import socket from "../../socket";

import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faBomb,
  faCircleUser,
  faComments,
  faCube,
  faPeopleGroup,
  faPlus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FaSvgIcon } from "../misc";
import styles from "./index.module.css";

const roomTypes = [
  {
    name: "Chat",
    icon: faComments,
  },
  {
    name: "Klasicna",
    icon: faCube,
  },
  {
    name: "Smrtonosna",
    icon: faBomb,
  },
];
const roomStates = ["Cekanje", "Pokrenuta", "Zavrsena", "Brbljavo"];

const RoomList = (props) => {
  const cntx = useContext(CentralContext);
  const { height, width, mobile } = screenSize();
  const [rooms, setRooms] = useState([]);
  const [roomFilter, setRoomFilter] = useState(0);
  const [automatic, setAutomatic] = useState(true);

  useEffect(() => {
    socket.off("rooms");
    socket.setupListener("rooms", (rooms) => {
      if (rooms.length > 5 && automatic) {
        setRoomFilter(1);
      } else if (rooms.length <= 5 && automatic) {
        setRoomFilter(0);
      }
      setRooms(
        rooms.filter((room) => {
          //console.log(room.state, roomFilter);
          if (roomFilter == 0) return room;
          else if (roomFilter == 1 && (room.state == 0 || room.state == 3))
            return room;
          else if (roomFilter == 2 && (room.state == 1 || room.state == 3))
            return room;
          else return false;
        }),
      );
    });
    return () => socket.off("rooms");
  }, [roomFilter]);

  useEffect(() => {
    setRoomFilter(cntx.roomListFilter);
  }, [cntx.roomListFilter]);

  const joinRoom = (roomId) => {
    socket.emit("joinRoom", roomId);
  };

  return !props.mobile ? (
    <div
      className={styles.all}
      style={!props.mobile ? { width: 600 } : { width: "100vw" }}
    >
      <div className={styles.belowRoomList} style={{ width: 600 }}>
        {cntx.settings.misc.help && (
          <p style={{ background: "#EEEEEE", padding: 5, borderRadius: 15 }}>
            Igrati možete tako da uđete u neku od već napravljenih soba dolje u
            popisu, a to napravite tako da kliknete na onu koja vam odgovara ili
            možete napraviti svoju klikom na gumb "Napravi Sobu" i pričekati da
            vam netko uđe u sobu.
            <span
              style={{ float: "right", color: "#2589F5", cursor: "pointer" }}
              onClick={() => {
                socket.emit("setSetting", {
                  help: false,
                });
              }}
            >
              {" "}
              -ne prikazuj vise pomocne poruke-
            </span>
          </p>
        )}
        <div className={styles.buttons}>
          <IconButton
            icon={<Icon as={FaSvgIcon} faIcon={faPlus} />}
            appearance="primary"
            onClick={props.newRoom}
          >
            Napravi Sobu
          </IconButton>
          <IconButton
            icon={<Icon as={FaSvgIcon} faIcon={faUserPlus} />}
            appearance="primary"
            onClick={() => props.setOpenedInvites(true)}
          >
            Pozivi
          </IconButton>
          <IconButton
            icon={<Icon as={FaSvgIcon} faIcon={faPlayCircle} />}
            appearance="default"
            onClick={() => props.setMedia(true)}
          >
            Media
          </IconButton>
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                appearance={roomFilter == 0 ? "primary" : "default"}
                onClick={() => {
                  setRoomFilter(0);
                  setAutomatic(false);
                }}
              >
                Sve
              </Button>
              <Button
                appearance={roomFilter == 1 ? "primary" : "default"}
                onClick={() => {
                  setRoomFilter(1);
                  setAutomatic(false);
                }}
              >
                Otvorene
              </Button>
              <Button
                appearance={roomFilter == 2 ? "primary" : "default"}
                onClick={() => {
                  setRoomFilter(2);
                  setAutomatic(false);
                }}
              >
                Pokrenute
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
      </div>
      <div className={styles.roomList}>
        <List hover>
          {rooms.map((room, i) => {
            return (
              <List.Item
                key={room.name}
                index={i}
                onClick={() => {
                  joinRoom(room.id);
                }}
              >
                <FlexboxGrid justify="space-between">
                  {/*icon*/}
                  <FlexboxGrid.Item colspan={2} className={styles.center}>
                    <Icon
                      as={FaSvgIcon}
                      faIcon={roomTypes?.[room?.type]?.icon}
                      style={{
                        color: "darkgrey",
                        fontSize: "1.5em",
                      }}
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item
                    colspan={8}
                    className={styles.center}
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflow: "hidden",
                    }}
                  >
                    <div className={styles.title}>{room.name}</div>
                    <div className={styles.slimText}>
                      <div>
                        <Icon
                          as={FaSvgIcon}
                          faIcon={faCircleUser}
                          style={{ fontSize: "0.75em" }}
                        />
                        {" " + room.ownerName}
                      </div>
                      <div>{new Date(room.created).toLocaleString()}</div>
                    </div>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item className={styles.center} colspan={4}>
                    <div style={{ textAlign: "right" }}>
                      <div className={styles.slimText}>Igraca</div>
                      <div className={styles.dataStyle}>{room.players}</div>
                    </div>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item className={styles.center} colspan={4}>
                    <div style={{ textAlign: "right" }}>
                      <div className={styles.slimText}>Tip sobe</div>
                      <div className={styles.dataStyle}>
                        {roomTypes[room.type].name}
                      </div>
                    </div>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item className={styles.center} colspan={6}>
                    <div style={{ textAlign: "right" }}>
                      <div className={styles.slimText}>Stanje</div>
                      <div className={styles.dataStyle}>
                        {roomStates[room.state]}
                      </div>
                    </div>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </List.Item>
            );
          })}
        </List>
      </div>
    </div>
  ) : (
    <div
      style={{
        overflow: "auto",
        maxHeight: "calc(var(--screenHeight) - 50px)",
      }}
    >
      {rooms && rooms.length ? (
        <List hover>
          {rooms.map((room, i) => {
            return (
              <List.Item
                key={room.name}
                index={i}
                onClick={() => {
                  joinRoom(room.id);
                }}
              >
                <FlexboxGrid justify="space-between">
                  {/*icon*/}
                  <FlexboxGrid.Item
                    colspan={12}
                    className={styles.center}
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflow: "hidden",
                      paddingLeft: 20,
                    }}
                  >
                    <div className={styles.title}>{room.name}</div>
                    <div className={styles.slimText}>
                      <div>
                        <Icon icon={faCircleUser} />
                        Igrača: {room.players}
                      </div>
                      <div>{roomTypes[room.type].name}</div>
                    </div>
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item className={styles.center} colspan={12}>
                    <div style={{ textAlign: "center" }}>
                      <div className={styles.slimText}>Stanje</div>
                      <div className={styles.dataStyle}>
                        {roomStates[room.state]}
                      </div>
                    </div>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </List.Item>
            );
          })}
        </List>
      ) : (
        "Pričekajte popis soba..."
      )}
    </div>
  );
};

export default RoomList;

import { useState, useEffect } from "react";
import { getBezier, Point } from "../engine";

import Field from "./field";

const Fields = props => {

  const [fields, setFields] = useState([]);
  const [bigCircle, setBigCircle] = useState([]);
  const [finishFields, setFinishFields] = useState([]);
  const [deathCircle, setDeathCircle] = useState([]);
  const width = 500;
  const height = 500;
  const DEBUG=false;

  useEffect(()=>{
    const bigCircleInit = [];
    const finishFieldsInit = [];
    for(var p=0;p<9;p++){
      var pos = getBezier((8-p)/9, Point(0-20, height/2-50), Point(width/4-20, height/2), Point(width/2-40, height/4), Point(width/2-40, 20));
      pos.color = (p==0)?"red":false;
      bigCircleInit.push(pos);
    }
    bigCircleInit.push({x:width/2, y:20});
    for(var p=0;p<9;p++){
      var pos = getBezier((8-p)/9, Point(width/2+40, -15), Point(width/2+40, height/4-40), Point(width/2+width/4, height/2-40), Point(width-20, height/2-40));
      pos.color = (p==0)?"blue":false;
      bigCircleInit.push(pos);
    }
    bigCircleInit.push({x:width-20,y:height/2});
    for(var p=0;p<9;p++){
      var pos = getBezier((8-p)/9, Point(width+20, height/2+40), Point(width/2+width/4, height/2+20), Point(width/2+40, height/2+height/4-20), Point(width/2+40, height-20));
      pos.color = (p==0)?"yellow":false;
      bigCircleInit.push(pos);
    }
    bigCircleInit.push({x:width/2,y:height-20});
    for(var p=0;p<9;p++){
      var pos = getBezier((8-p)/9, Point(width/2-40, height+10), Point(width/2-10, height/2+height/4+40), Point(width/4-10, height/2+40), Point(20, height/2+40));
      pos.color = (p==0)?"green":false;
      bigCircleInit.push(pos);
    }
    bigCircleInit.push({x:20,y:height/2});

    for(var rh=4;rh>0;rh--){
      finishFieldsInit.push({x:width/2-width/12*(rh-1)-70, y:height/2, color:"red"});
    }
    for(var bh=4;bh>0;bh--){
      finishFieldsInit.push({x:width/2, y:height/2-width/12*(bh-1)-70, color:"blue"});
    }
    for(var yh=4;yh>0;yh--){
      finishFieldsInit.push({x:width/2+width/12*(yh-1)+70, y:height/2, color:"yellow"});
    }
    for(var gh=4;gh>0;gh--){
      finishFieldsInit.push({x:width/2, y:height/2+width/12*(gh-1)+70, color:"green"});
    }
    setBigCircle(bigCircleInit);
    setFinishFields(finishFieldsInit);
    setFields([...bigCircleInit, ...finishFieldsInit]);
  }, [])

  const setElMuerteCircle = (field) => {
    let deathCircleInit = [];
    let fieldCoords = [];
    for(var p=0;p<13;p++){
      var pos = {x:0,y:0};
      pos.x = 250 + 100 * Math.cos(((2*Math.PI)/13)*p);
      pos.y = 250 + 100 * Math.sin(((2*Math.PI)/13)*p);
      pos.color = (p==(field-1))?"red":false;
      deathCircleInit.push(pos);
    }
    setDeathCircle(deathCircleInit);
    setFields([...deathCircleInit, ...bigCircle]);
  }

  const setElMuerte = () => {
    let fieldCoords = [];
    for(var f=0;f<bigCircle.length;f++){
        fieldCoords.push(bigCircle[f]);
    }
    for(f=0;f<deathCircle.length;f++){
        fieldCoords.push(deathCircle[f]);
    }
    setFields(fieldCoords);
  }

  useEffect(()=>{
    props.updateParent(fields);
  }, [fields]);

  useEffect(()=>{
    if(DEBUG)console.log("gameType is ", props.gameType, props.muerte, props.muerteOut);
    if(props.gameType==1){
      setFields([...bigCircle, ...finishFields])
    } else if(props.gameType==2 && !props.muerte){
      setFields([...bigCircle]);
    } else if(props.gameType==2 && props.muerte){
      setElMuerteCircle(props.muerteOut);
      setFields([...deathCircle, ...bigCircle]);
    }
  }, [props.gameType, props.muerteOut])

  useEffect(()=>{
    if(props.muerte){
      setElMuerteCircle(props.muerteOut);
    } else {
      setFields([...bigCircle]);
    }
  }, [props.muerte, props.muerteOut])

  return (
    fields.map((f,i)=>{
      return <Field data={f} key={i} id={i}/>
    })
  )
}

export default Fields

const loadFBsdk = (load, callback) => {
  const existingScript = document.getElementById('facebook-jssdk');
  if (!existingScript && load) {
    const script = document.createElement('script');
    script.src =
      'https://connect.facebook.net/en_US/sdk.js?version=v14.0&xfbml=1&appId=' +
      process.env.REACT_APP_FB_APP_ID;
    script.id = 'facebook-jssdk';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
    script.onerror = (e) => {
      callback({ error: true, msg: e });
    };
  }
  if (existingScript && callback) callback();
};

export default loadFBsdk;

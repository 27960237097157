export default {
  red: {
    clip: {
      x: 0,
      y: 0
    },
    circle: {
      x: 0,
      y: 0
    },
    text: {
      x: 0,
      y: 15
    }
  },
  blue: {
    clip: {
      x: 350,
      y: 0
    },
    circle: {
      x: 500,
      y: 0
    },
    text: {
      x: 360,
      y: 15
    }
  },
  yellow: {
    clip: {
      x: 350,
      y: 350
    },
    circle: {
      x: 500,
      y: 500
    },
    text: {
      x: 360,
      y: 475
    }
  },
  green: {
    clip: {
      x: 0,
      y: 350
    },
    circle: {
      x: 0,
      y: 500
    },
    text: {
      x: 0,
      y: 475
    }
  }
}

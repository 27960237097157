import io from "socket.io-client";
let socket;
let counter = 0;

export const initiateSocket = (connectionStateCallback) => {
  console.log(
    `ha ${(process.env.REACT_APP_NODE_ENV !== "local" && process.env.REACT_APP_ROOT) || "http://192.168.1.148:4000"}`,
  );
  console.log("root:", process.env.REACT_APP_ROOT || window.location.origin);
  if (socket) {
    console.log("socket already connected");
    connectionStateCallback(true);
    return true;
  }
  if (socket && !socket.connected) {
    console.log("not connected");
    // connectionStateCallback(false);
  }
  socket = io(`${process.env.REACT_APP_ROOT || "http://192.168.1.148:4000"}`, {
    withCredentials: true,
  });
  socket.on("connect_error", console.error);
  socket.off("connect");
  socket.on("connect", () => {
    counter++;
    console.log("io connected", counter);
    connectionStateCallback(true);
  });
  socket.off("disconnect");
  socket.on("disconnect", () => {
    console.log("disco disco");
    connectionStateCallback(false);
  });
  console.log(`Connecting socket...`);
};

export const disconnect = () => {
  console.log("Disconnecting socket...");
  if (socket) socket.disconnect();
};

export const setupListener = (e, cb) => {
  if (!socket) return true;
  //console.log("setting up listener for", e);
  socket.off(e);
  socket.on(e, (msg) => {
    if (e != "rooms") console.log(e + " event received!");
    return cb(msg);
  });
};

export const sendMessage = (room, message) => {
  if (socket) socket.emit("chat", { message, room });
};

export const emit = (e, data) => {
  socket.emit(e, data);
};

export const off = (e) => {
  socket.off(e);
};

export const getInstance = () => {
  console.log("socket instance", socket);
  return socket;
};

export default {
  getInstance: getInstance,
  initiateSocket: initiateSocket,
  disconnect: disconnect,
  setupListener: setupListener,
  sendMessage: sendMessage,
  emit: emit,
  off: off,
};

import {useState} from 'react';
import { Group, Circle, Text } from 'react-konva';

const Field = props => {
  return (
    <Group>
      <Circle x={props.data.x} y={props.data.y} fill={props.data.color || "#DDDDDD"} radius={15} key={props.index} />
      {/*<Text x={props.data.x-5} y={props.data.y-5} text={""+props.id}/>*/}
    </Group>
  );
}

export default Field;

import React, { useState, useEffect, useRef } from "react";
import { Sprite, Group, Image } from "react-konva";
import useImage from "use-image";

const Figure = props => {
  const figRef = useRef();
  //if(DEBUG)console.log(props);
  const colors = ["red", "blue", "yellow", "green"];
  const oppositeColor = ["blue", "red", "green", "yellow"];
  const types = ["metla", "terminator", "devil", "sheriff", "jason",//0,1,2,3,4
                 "ghandi", "vrisak", "mraz", "horns", "kiklop",//5,6,7,8,9
                 "kiklopBig", "krak", "ktulu", "unicornSkull", "witch",//10,11,12,13,14
                 "bat", "eye", "pimpReaper", "snail", "dinjosaur",//15,16,17,18,19
                 "dinjosaurus", "ET", "ET2", "fist", "fungus",//20,21,22,23,24
                 "viking"];
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [alive, setAlive] = useState(props.alive);
  const [shadow, setShadow] = useState({
    shadowColor: "black",//oppositeColor[colors.indexOf(props.color)],
    shadowBlur: 25,
    shadowOffset: {x: 0, y: -20},
    shadowOpacity: 0
  });
  const [onField, setOnField] = useState(props.onField);
  const DEBUG=false;

  const getRandomType = ()=>{
    return types[Math.floor(Math.random()*types.length)];
  }

  const [figures, setFigures] = useState(props.types || [getRandomType(),getRandomType(),getRandomType(),getRandomType()]);
  const [color, setColor] = useState(props.color || colors[0]);

  const [inDeathCircle, setInDeathCircle] = useState(false);


  const colorToHr = (color) => {
    switch (color) {
      case 0:
      case "red":
          return "crveni";
      case 1:
      case "blue":
          return "plavi";
      case 2:
      case "yellow":
          return "zuti";
      case 3:
      case "green":
          return "zeleni";
        break;
      default:

    }
  }

  const imageName = (figure)=>{
    return "Lik_"+types[figure]+"_"+colorToHr(color)+".png";
  }

  const [image] = useImage('/assets/'+imageName(props.type));

  const scaleX = 0.37;
  const scaleY = 0.37;

  useEffect(()=>{
    goto((onField-1));
  }, [])

  useEffect(()=>{
    if(props?.update?.players?.[props.color]?.figures[props.fig]){
      let figDef = props.update.players[props.color].figures[props.fig];
      if(figDef?.onField!=undefined){
        setOnField(figDef.onField)
      }
      if(figDef?.movable){
        setShadow({...shadow, shadowOpacity: 1});
      } else {
        setShadow({...shadow, shadowOpacity: 0});
      }
      if(figDef?.alive || figDef?.alive===0){
        setAlive(figDef.alive);
      }
    }
    /*if(props?.update?.move && props.update.move[props.color][0]==props.fig){
      if(DEBUG)console.log("figure", props.color, props.fig, "to update field to", props.update.move[props.color][1]);
      goto(props.update.move[props.color][1]);
    }
    if(props?.update?.[props.color]?.figure?.movable && props?.update?.[props.color]?.figure?.movable.indexOf(props.fig)!=-1){
      if(DEBUG)console.log("mogu se pomaknuti figure:", props.color, props.fig);
      setShadow({...shadow, shadowOpacity: 1});
    } else {
      setShadow({...shadow, shadowOpacity: 0});
    }

    if(props?.update?.board?.positions && props?.update?.board?.positions.length){
      for(let p=0;p<props.update.board.positions.length;p++){
        if(props.update.board.positions[p].color == props.color && props.update.board.positions[p].figure==props.fig){
          setOnField(props.update.board.positions[p].position)
        }
      }
    }
    if(props?.update?.[props.color]?.figure==props.fig){
      setAlive(props.update[props.color].alive);
    }
    if(props?.update?.players?.[props.color]?.alive[props.fig] || props?.update?.players?.[props.color]?.alive[props.fig]===0){
      setAlive(props.update.players.[props.color].alive[props.fig]);
    }*/
  }, [props.update])

  useEffect(()=>{
    if(onField>=0){
      goto((onField-1));
    }
  }, [onField]);

  useEffect(()=>{
    if(onField>=0){
      goto((onField-1));
    }
  }, [props.onField, props.fields]);

  const goto = (fieldId)=> {
    if(DEBUG)console.log("fetch field id", fieldId, props.color, props.fig);
    let nextPos = {...props.goto(fieldId, props.color, props.fig)}
    nextPos.x = nextPos.x-10;
    nextPos.y = nextPos.y-50;
    //console.log("go to", nextPos);
    if(nextPos.x && nextPos.y){
      figRef.current.to(nextPos);
    } else console.error("no next pos", nextPos);
  }

  return (
    <Image onClick={props.onClick} onTap={props.onTapWorkAround} ref={figRef} image={image} scaleX={scaleX} scaleY={scaleX} key={props.figId} x={x/*props.x-10*/}  y={y/*props.y-50*/} opacity={alive} shadowBlur={shadow.shadowBlur} shadowColor={shadow.shadowColor} shadowOffset={shadow.shadowOffset} shadowOpacity={shadow.shadowOpacity}/>
  )
}

export default Figure;

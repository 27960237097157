import { useState, useEffect } from 'react';
import { Group, Circle } from 'react-konva';

const TurnIndicator = props => {
  const [color, setColor] = useState(props.turn)
  const colorNames = ["red", "blue", "yellow", "green"];

  const numberToColor = (num) => {
    return colorNames[num];
  }

  useEffect(()=>{
    if(props?.update?.turn || props?.update?.turn==0){
      //console.log("update turn", props.update.turn);
      setColor(numberToColor(props.update.turn))
    }
  }, [props.update])

  return(
    <Circle x={250} y={250} fill={color} radius={50} />
  )
}

export default TurnIndicator;

import React, { useEffect, useRef, useState } from "react";

import Canvas from "./Canvas";
import Board from "./board";
import Dice from "./dice";
import Figure from "./figure";
import LoaderSvc from "./loaderSvc";

const createjs = window.createjs;
const persistant = {};

const Game = (props) => {
  console.log("game props", props);
  const scale = {
    x: props.width ? props.width / 600 : 1,
    y: props.height ? props.height / 600 : 1,
  };
  const [loading, setLoading] = useState(true);
  const canvas = useRef(null);
  const [names, setNames] = useState({
    red: "-slobodno-",
    blue: "-slobodno-",
    green: "-slobodno-",
    yellow: "-slobodno-",
  });
  //var w, h, container, board, dice, figures = new Array(4), tst=true, nameTags = new Array(4), x;
  const figures = new Array(4);
  const nameTags = new Array(4);
  let w,
    h,
    container,
    x,
    lw = 0,
    lh = 0;

  const colorToNum = (color) => {
    switch (color) {
      case "red":
        return 0;
      case "blue":
        return 1;
      case "yellow":
        return 2;
      case "green":
        return 3;
    }
  };

  useEffect(() => {
    console.log("set gameplay", props?.gameplay);
    switch (props?.gameplay?.name) {
      case "colorSet":
        let name = {};
        name[props.gameplay.color] = props.gameplay.player.name;
        setNames({ ...names, ...name });
        break;
      default:
        console.log("gameplaye is probably undefined");
    }
  }, [props.gameplay]);

  useEffect(() => {
    console.log("names changed to", names);
    for (let color in names) {
      //console.log(color, names[color]);
      if (nameTags && nameTags[colorToNum(color)])
        nameTags[colorToNum(color)].getChildAt(1).text = names[color];
    }
    if (persistant.stage) persistant.stage.update();
  }, [names]);

  const handleAssetsLoaded = () => {
    setLoading(false);
  };

  const draw = (ctx, frameCount) => {
    if (persistant.stage) {
      persistant.stage.update();
    } //else console.log("no stage");
  };

  const onResize = () => {
    if (persistant.stage) {
      persistant.stage.update();
    } else console.log("no stage");
  };

  const init = () => {
    const stage = new createjs.Stage(canvas.current);
    persistant.stage = stage;
    stage.autoClear = true;
    stage.removeAllChildren();
    stage.update();
    w = stage.canvas.width;
    h = stage.canvas.height;
    container = new createjs.Container();
    var bg = new createjs.Shape();
    bg.graphics.beginFill("white");
    bg.graphics.drawRect(0, 0, 500 * scale.x, 500 * scale.y);
    bg.setBounds(0, 0, 500 * scale.x, 500 * scale.y);
    bg.cache();
    container.addChild(bg);
    stage.addChild(container);
    container.x = 100 * scale.x; //w/2-container.width/2;
    container.y = 20 * scale.y; //h/2-container.height/2;
    x = new createjs.Bitmap(LoaderSvc.getResult("x"));
    x.on("click", function () {
      console.log("exiting in game canvas");
    });
    stage.addChild(x);
    Board.setScale(scale);
    Board.constructor();
    Board.addToStage(container);
    Dice.setScale(scale);
    Dice.addToStage(container);
    setupFigures({
      figures: [1, 2, 2, 7],
      position: 0,
    });
    setupFigures({
      figures: [3, 4, 5, 1],
      position: 1,
    });
    setupFigures({
      figures: [5, 6, 7, 3],
      position: 2,
    });
    setupFigures({
      figures: [7, 0, 4, 7],
      position: 3,
    });
    rearrangeFigures();
    createjs.Ticker.timingMode = createjs.Ticker.RAF;
    createjs.Ticker.addEventListener("tick", tick);
    for (var n = 0; n < 4; n++) {
      let tagBg = new createjs.Bitmap(LoaderSvc.getResult("nameTag"));
      tagBg.scaleX = 0.7;
      tagBg.scaleY = 0.7;
      let nameTag = new createjs.Text("name" + n, "16px Arial", "#000000");
      nameTag.textAlign = "center";
      nameTags[n] = new createjs.Container();
      nameTags[n].addChild(tagBg);
      nameTags[n].addChild(nameTag);
      nameTag.y = 25 * tagBg.scaleY;
      nameTag.x = (139 * 0.7) / 2;
      switch (n) {
        case 0:
          nameTags[n].x = 10 * scale.x;
          nameTags[n].y = 5 * scale.y;
          break;
        case 1:
          nameTags[n].x = 400 * scale.x;
          nameTags[n].y = 5 * scale.y;
          break;
        case 2:
          nameTags[n].x = 400 * scale.x;
          nameTags[n].y = 470 * scale.y;
          break;
        case 3:
          nameTags[n].x = 10 * scale.x;
          nameTags[n].y = 470 * scale.y;
          break;
      }
      nameTag.textBaseline = "alphabetic";
      container.addChild(nameTags[n]);
    }
  };

  const setupFigures = (figs) => {
    figures[figs.position] = new Array(4);
    for (let f = 0; f < 4; f++) {
      figures[figs.position][f] = new Figure(
        {
          color: figs.position,
          id: f,
          type: figs.figures[f],
          homeLocation: Board.getHomePositions(figs.position)[f],
        },
        function move() {
          console.log("implement move function");
        },
      );
      figures[figs.position][f].setScale(scale);
      figures[figs.position][f].addToStage(container);
    }
  };
  const rearrangeFigures = () => {
    let tmpY = Board.getY();
    Board.setY(-1000);
    container.sortChildren(function (obj1, obj2, options) {
      if (obj1.y > obj2.y) {
        return 1;
      }
      if (obj1.y < obj2.y) {
        return -1;
      }
      return 0;
    });
    Board.setY(tmpY);
  };
  const glowFigures = (color, figs) => {
    for (let f = 0; f < 4; f++) {
      figures[color][f].getInstance().shadow = null;
    }
    if (figs != null && figs.length > 0) {
      for (var f = 0; f < figs.length; f++) {
        figures[color][figs[f]].getInstance().shadow = new createjs.Shadow(
          "#FFFFFF",
          0,
          0,
          15,
        );
      }
    }
  };

  const tick = (event) => {
    /*w = stage.canvas.scrollWidth;
    h = stage.canvas.scrollHeight;
    if(w!=lw || lh!=h){
      lw = w;
      lh = h;
      stage.canvas.width = w;
      stage.canvas.height = h;
      resize(w, h);
      var containerBounds = container.getBounds();
      container.x = w/2 - (containerBounds.width*container.scaleX)/2;
      //container.y = h/2 - (containerBounds.height*container.scaleY)/2;
      //Dice.setX(Board.getWidth()/2 - Dice.getRealWidth());
      //Dice.setY(Board.getHeight()/2 - Dice.getRealHeight()/1.3);
    }*/
    x.x = w - 60;
    x.y = 10;
    for (var color in names) {
      nameTags[colorToNum(color)].getChildAt(1).text = names[color];
    }
    if (
      props?.gameplay != null &&
      props?.gameplay?.players &&
      props?.gameplay?.updatePlayers
    ) {
      console.log("updating names", props.gameplay);
      props.gameplay.updatePlayers = false;
      for (var n = 0; n < 4; n++) {
        nameTags[n].getChildAt(1).text = "-slobodno-";
      }
      for (var p in props.gameplay.players) {
        nameTags[colorToNum(p)].getChildAt(1).text =
          props.gameplay.players[p].player.name;
        for (var f = 0; f < 4; f++) {
          console.log(
            "resurecting: ",
            colorToNum(p),
            f,
            "alive: ",
            props.gameplay.players[p].alive[f],
          );
          if (props.gameplay.players[p].alive[f]) {
            figures[colorToNum(p)][f].resurect(); // = props.gameplay.players[p].alive[f];
          } else figures[colorToNum(p)][f].die();
        }
      }
    }

    if (
      props?.gameplay != null &&
      props?.gameplay?.dice != null &&
      props?.gameplay?.dice.play
    ) {
      props.gameplay.dice.play = false;
      Dice.setNum(props.gameplay.dice.value);
      if (props.gameplay.turn != null) {
        glowFigures(props.gameplay.turn.num, props.gameplay.movableFigs);
      }
    }
    if (
      props?.gameplay != null &&
      props?.gameplay?.reset &&
      (props?.gameplay?.movement == null ||
        (props?.gameplay?.movement != null &&
          props?.gameplay?.movement?.length == 0))
    ) {
      console.log("reset figures: ", props.gameplay);
      props.gameplay.reset = false;
      var color, figure, position;
      for (var c = 0; c < 4; c++) {
        for (var f = 0; f < 4; f++) {
          figures[c][f].resetToHome();
          figures[c][f].resurect();
        }
        nameTags[c].text = "";
      }
      if (props.gameplay.muerte) {
        console.log("setting muerte");
        Board.setElMuerteCircle(props.gameplay.muerteOut);
      }
      if (props.gameplay.players != null) {
        for (c in props.gameplay.players) {
          if (
            props.gameplay.players[c].alive != null &&
            props.gameplay.players[c].alive.length > 0
          ) {
            for (var a = 0; a < props.gameplay.players[c].alive.length; a++) {
              if (props.gameplay.players[c].alive[a] == 0) {
                figures[colorToNum(c)][a].die();
              }
            }
          }
        }
      }
      if (props.gameplay.positions != null) {
        for (var p = 0; p < props.gameplay.positions.length; p++) {
          color = colorToNum(props.gameplay.positions[p].color);
          figure = props.gameplay.positions[p].figure;
          position = props.gameplay.positions[p].position;
          //console.log("set ["+color+"]["+figure+"]: ", position);
          if (position == 0) {
            console.log("zero!");
            moveFigure(
              figures[color][figure],
              figures[color][figure].getHomeCoords(),
            );
          } else {
            console.log("get position: ", position - 1);
            moveFigure(
              figures[color][figure],
              Board.getPositionCoords(position - 1),
            );
          }
        }
      }
      if (props.gameplay.muerte == false) {
        Board.setType(props.gameplay.roomType);
      }
    }
    if (
      props?.gameplay != null &&
      props?.gameplay?.movement != null &&
      props?.gameplay?.movement?.length > 0
    ) {
      var last = props.gameplay.movement.length - 1;
      var movement = props.gameplay.movement[last];
      var figure = figures[colorToNum(movement.color)][movement.figure];
      if (movement.onField == 0) {
        moveFigure(figure, figure.getHomeCoords());
      } else {
        moveFigure(figure, Board.getPositionCoords(movement.onField - 1));
      }
      if (movement.alive == 0) {
        figure.die();
        props.gameplay.players[movement.color].alive[movement.figure] = 0;
      }
      props.gameplay.movement.pop();
      glowFigures(colorToNum(movement.color), []);
    }
    if (
      props?.gameplay?.turn != null &&
      props?.gameplay?.turn?.isSet == false
    ) {
      Board.setTurn(props.gameplay.turn.num);
      props.gameplay.turn.isSet = true;
    }
    var deltaS = event.delta / 1000;
    if (persistant.stage) persistant.stage.update(event);
    //props.updateFinished();
    //board.setTurn(2)
  };

  const moveFigure = (fig, coords) => {
    createjs.Tween.get(fig.getInstance())
      .to(
        {
          x: coords.x + fig.getCorrection().x,
          y: coords.y + fig.getCorrection().y,
        },
        500,
        createjs.Ease.getPowInOut(4),
      )
      .call(function () {
        rearrangeFigures();
      });
  };

  function resize(pw, ph) {
    var scale = 1;
    if (pw > ph) {
      scale = (ph * 500) / 500 / 500;
    } else if (pw <= ph) {
      scale = (pw * 500) / 500 / 500;
    }
    container.scaleX = container.scaleY = scale - scale * 0.1;
  }

  useEffect(() => {
    //setupStage
    console.log("loading...", loading);
    if (!loading) {
      init();
    }
  }, [loading]);

  LoaderSvc.getLoader().addEventListener("complete", handleAssetsLoaded);
  LoaderSvc.loadAssets();

  return (
    <>
      <canvas
        ref={canvas}
        id="gameCanvas"
        style={{ width: "100%", height: "100%" }}
        width={props.width || 600}
        height={props.height || 600}
        onResize={onResize}
      />
    </>
  );
};

//<Canvas draw={draw} style={{width:"100%", height:"100%"}} width={600} height={600} onResize={onResize}/>
export default Game;

import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  InputGroup,
  IconButton,
  Panel,
  Table,
  Divider,
  Progress,
} from "rsuite";
import { Icon } from "@rsuite/icons";
import { Link } from "react-router-dom";
import socket from "../../socket";
import userService from "../../services/user.service";

const { Column, HeaderCell, Cell, Pagination } = Table;

const AdminPage = (props) => {
  const [authed, setAuthed] = useState(false);
  const [users, setUsers] = useState(false);
  const [page, setPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(false);
  const [announcement, setAnnouncement] = useState("");
  const [search, setSearch] = useState("");
  const [chatList, setChatList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [fakes, setFakes] = useState([]);
  const [stats, setStats] = useState({
    cc: 0,
    maxCC: 0,
  });
  const getUsers = (options) => {
    socket.emit("admin", {
      cmd: "users",
      limit: 50,
      skip: (page - 1) * (options?.limit || 50),
      search: search,
    });
  };

  useEffect(() => {
    if (socket.getInstance()) {
      adminCheck();
    } else {
      socket.initiateSocket(() => {
        console.log("connected");
        adminCheck();
      });
    }
    return () => {
      socket.off("admin");
    };
  }, []);

  useEffect(() => {
    if (authed) {
      getUsers();
      socket.setupListener("chatList", (chatList) => {
        setChatList(chatList);
      });
    }
  }, [authed]);

  const adminCheck = () => {
    socket.off("admin");
    socket.setupListener("admin", (data) => {
      console.log(data);
      if (data.admin) {
        console.log("continue", data);
        setAuthed(true);
      } else {
        console.log("not an admin");
      }

      if (data.users) {
        setUsers(data.users);
        setTotalUsers(data.total);
      }
    });
    socket.setupListener("chatDetails", (data) => {
      console.log("got chats", data);
      setChatMessages(data);
    });
    socket.setupListener("stats", (data) => {
      //console.log("got stats", data);
      setStats(data);
    });
    socket.setupListener("fakes", (data) => {
      console.log("got fakes", data);
      setFakes(data);
    });
    getStats();
    socket.emit("admin", { cmd: "check" });
  };

  const getStats = () => {
    setTimeout(getStats, 5000);
    socket.emit("admin", { cmd: "stats" });
  };

  const changePage = (page) => {
    console.log("page:", page);
    setPage(page);
  };
  useEffect(() => {
    getUsers();
  }, [page]);

  const announce = () => {
    socket.emit("admin", { cmd: "announcement", msg: announcement });
  };

  const getDetails = (rowData) => {
    setSelectedUser(rowData);
    socket.emit("admin", { cmd: "details", id: rowData.id });
  };

  const setBan = (rowData) => {
    socket.emit("admin", { cmd: "ban", id: rowData.id });
  };

  const setMute = (rowData) => {
    socket.emit("admin", { cmd: "mute", id: rowData.id });
  };

  return (
    <div>
      {authed && users && (
        <div>
          <IconButton
            icon={<Icon icon="cube" />}
            size="lg"
            color="orange"
            componentClass={Link}
            to="/"
          />
          <Divider />
          <div>
            <InputGroup>
              <Input
                placeholder="server announcement"
                onChange={setAnnouncement}
              />
              <InputGroup.Button onClick={announce}>Salji</InputGroup.Button>
            </InputGroup>
          </div>
          <Divider />
          {fakes && (
            <div>
              {fakes.map((f) => {
                return (
                  <p>
                    {f.user.basic.email} - mute:{" "}
                    {f.user.restrictions.mute ? "yes" : "no"} - ban:{" "}
                    {f.user.restrictions.ban ? "yes" : "no"}
                  </p>
                );
              })}
            </div>
          )}
          <Divider />
          {!!chatList.length && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                {chatList.map((ch) => {
                  return (
                    <p
                      onClick={() => {
                        socket.emit("admin", {
                          cmd: "chatDetails",
                          from: selectedUser.id,
                          to: ch,
                        });
                      }}
                    >
                      {ch}
                    </p>
                  );
                })}
              </div>
              {!!chatMessages && (
                <div>
                  {chatMessages.map((cm) => {
                    return (
                      <p>
                        {cm.user}: {cm.text}
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          <Divider />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>
              {stats.cc}/{stats.maxCC}
            </p>
            <Progress.Line
              percent={((stats.cc / stats.maxCC) * 100).toFixed(1)}
              status="active"
            />
          </div>
          <Divider />
          <Button
            onClick={() => socket.emit("admin", { cmd: "getDuplicates" })}
          >
            Duplici
          </Button>
          <InputGroup inside>
            <Input placeholder="User name" onChange={setSearch} />
            <InputGroup.Button onClick={() => getUsers()}>
              <Icon icon="search" />
            </InputGroup.Button>
          </InputGroup>
          <Table
            height={500}
            width={900}
            data={users}
            onRowClick={(data) => {
              console.log(data);
            }}
          >
            <Column align="center" resizable>
              <HeaderCell>Id</HeaderCell>
              <Cell dataKey="id" />
            </Column>

            <Column resizable>
              <HeaderCell>Name</HeaderCell>
              <Cell>
                {(rowData) => {
                  return <p>{rowData?.basic?.name}</p>;
                }}
              </Cell>
            </Column>

            <Column resizable>
              <HeaderCell>Na igri od:</HeaderCell>
              <Cell>
                {(rowData) => {
                  return (
                    <p>
                      {new Date(rowData?.basic?.createdDate)
                        .toISOString()
                        .slice(0, 10)}
                    </p>
                  );
                }}
              </Cell>
            </Column>

            <Column width={60}>
              <HeaderCell>Slika</HeaderCell>
              <Cell style={{ padding: 0, paddingLeft: 5, paddingRight: 0 }}>
                {(rowData) => {
                  return <img src={rowData?.basic?.picture} />;
                }}
              </Cell>
            </Column>

            <Column width={70}>
              <HeaderCell>Figure</HeaderCell>
              <Cell>
                {(rowData) => {
                  return rowData?.settings?.figureTypes.map((fig) => {
                    return fig + " ";
                  });
                }}
              </Cell>
            </Column>

            <Column width={80}>
              <HeaderCell>Otkljucane</HeaderCell>
              <Cell>
                {(rowData) => {
                  return rowData?.game?.figures.map((fig) => {
                    return fig + " ";
                  });
                }}
              </Cell>
            </Column>

            <Column width={80}>
              <HeaderCell>Nagrade</HeaderCell>
              <Cell>
                {(rowData) => {
                  return rowData?.game?.rewards.map((fig) => {
                    return fig + " ";
                  });
                }}
              </Cell>
            </Column>

            <Column width={160} fixed="right">
              <HeaderCell>Opcije</HeaderCell>

              <Cell>
                {(rowData) => {
                  function handleAction() {
                    alert(`id:${rowData.id}`);
                  }
                  return (
                    <span>
                      <a onClick={() => setBan(rowData)}> Ban </a> |{" "}
                      <a onClick={() => setMute(rowData)}> Mute </a> |{" "}
                      <a onClick={() => getDetails(rowData)}> Details </a>
                    </span>
                  );
                }}
              </Cell>
            </Column>
          </Table>
          <Pagination
            activePage={page}
            total={totalUsers}
            onChangePage={changePage}
            displayLength={50}
          />
        </div>
      )}
      {!authed && <div>gubi se</div>}
    </div>
  );
};

export default AdminPage;

import React, { useState, useEffect, useRef } from "react";
import { Sprite, Group, Image } from "react-konva";
import useImage from "use-image";
import soundFX from "../../../soundfx";

const Dice = props => {
  const diceRef = useRef();
  const [image] = useImage('/assets/Dice-anim.png');
  const [dice1] = useImage('/assets/Dice-1.png');
  const [dice2] = useImage('/assets/Dice-2.png');
  const [dice3] = useImage('/assets/Dice-3.png');
  const [dice4] = useImage('/assets/Dice-4.png');
  const [dice5] = useImage('/assets/Dice-5.png');
  const [dice6] = useImage('/assets/Dice-6.png');
  const dices = [dice1, dice2, dice3, dice4, dice5, dice6];
  const [currentFrame, setCurrentFrame] = useState(0);
  const [anim, setAnim] = useState(true);
  const [currentDiceNumber, setCurrentDiceNumber] = useState(5);
  const animations = {
    throw:[
      0,0,129,128,
      129,0,158,161,
      287,0,180,192,
      467,0,191,208,
      658,0,214,196,
      0,208,215,180,
      215,208,216,198,
      431,208,221,243,
      652,208,233,265,
      0,473,235,256,
      235,473,230,239,
      465,473,240,240,
      705,473,257,262,
      0,735,273,294,
      273,735,271,294,
      544,735,235,261,
      779,735,213,208,
      0,1029,215,185,
      215,1029,198,201,
      413,1029,182,199,
      595,1029,178,192,
      773,1029,173,168,
      0,1230,161,146,0//,
      //161,1230,153,131
    ]
  };

  useEffect(()=>{
    if(diceRef && diceRef.current){
      diceRef.current.start();
      diceRef.current.on("frameIndexChange", onFrame)
    }
  }, [])

  useEffect(()=>{
    playDiceAnimation();
  }, [currentDiceNumber]);

  const playDiceAnimation = ()=>{
    setAnim(true);
    if(diceRef && diceRef.current){
      diceRef.current.start();
      setCurrentFrame(0);
      soundFX.playGameSound("dice");
    }
  }

  useEffect(()=>{
    //console.log("diceRef change", diceRef.current, currentFrame);
    if(diceRef.current!=null)diceRef.current.start();
  }, [diceRef.current])

  useEffect(()=>{
    if(props?.update?.dice){
      setCurrentDiceNumber(props.update.dice);
      if(props.update.dice==currentDiceNumber){
        playDiceAnimation();
      }
    }
  }, [props.update])

  const onFrame = (frame)=>{
    //console.log("frame:", frame);
    if(frame.oldVal==23 && frame.newVal==0){
      //console.log("animation finished");
      if(diceRef && diceRef.current){
        diceRef.current.stop();
      }
      setAnim(false);
    }
  }

  return (
    <Group>
      {!anim &&
        <Group x={225} y={225} scaleX={0.4} scaleY={0.4}>
          {currentDiceNumber==1 && <Image image={dice1} onClick={props.onClick} onTap={props.onClick} />}
          {currentDiceNumber==2 && <Image image={dice2} onClick={props.onClick} onTap={props.onClick} />}
          {currentDiceNumber==3 && <Image image={dice3} onClick={props.onClick} onTap={props.onClick} />}
          {currentDiceNumber==4 && <Image image={dice4} onClick={props.onClick} onTap={props.onClick} />}
          {currentDiceNumber==5 && <Image image={dice5} onClick={props.onClick} onTap={props.onClick} />}
          {currentDiceNumber==6 && <Image image={dice6} onClick={props.onClick} onTap={props.onClick} />}
        </Group>
      }
      <Sprite
        visible={anim}
        ref={diceRef}
        image={image}
        animation="throw"
        frameRate={40}
        frameIndex={currentFrame}
        animations={animations}
        x={225}
        y={225}
        scaleX={0.5}
        scaleY={0.5}
      />
    </Group>
  )
}

export default Dice;

import {
  faDiceFive,
  faDiceFour,
  faDiceOne,
  faDiceSix,
  faDiceThree,
  faDiceTwo,
} from "@fortawesome/free-solid-svg-icons";
import { Icon } from "@rsuite/icons";
import React, { useEffect, useState } from "react";
import { IconButton } from "rsuite";
import Game from "../../components/Game";
import { DefaultRoomUpdate, FaSvgIcon } from "../../components/misc";
import socket from "../../socket";

const TV = () => {
  return <div>teve</div>;
};

export const TVNew = () => {
  const [debugtxt, setDebugtxt] = useState("txt");
  const [pressedKey, setPressedKey] = useState(false);
  const [connected, setConnected] = useState(false);
  const [update, setUpdate] = useState({ ...DefaultRoomUpdate });

  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  );

  const initSocket = () => {
    console.log("initing socket");
    socket.initiateSocket((c) => {
      setDebugtxt("sokat" + c);
      socket.emit("tv", { test: "yeah" });
      setConnected(c);
    });
    socket.setupListener("youAre", (data) => {
      setDebugtxt(JSON.stringify(data));
      // cntx.setUser(data);
    });
    socket.setupListener("randomMovement", (data) => {
      setDebugtxt(JSON.stringify(data));
      // cntx.setUser(data);
    });
  };

  useEffect(() => {
    if (connected) {
      // setDebugtxt("Send something");
      socket.emit("whoAmI");
    }
    // setDebugtxt("env " + process.env.REACT_APP_NODE_ENV);
  }, [connected]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "1") {
        socket.emit("tv", { test: "yeah" });
      }
      if (event.key === "2") {
        setDebugtxt("c" + socket.getInstance().connected);
      }
      if (event.key === "3") {
        socket.getInstance().connect();
      }
      if (event.key === "x") {
        event.preventDefault();
        console.log("active element", document.activeElement);
        // socket.emit("throwDice"); // Replace 'yourCommand' with the command you want to send
      } else {
        event.preventDefault();
        console.log(event.key);
        // setDebugtxt("key: " + event.key);
        setPressedKey(event.key);
      }
    };
    const handleKeyUp = (event) => {
      setPressedKey(false);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    initSocket();
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyDown);
    };
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: vh <= vw ? vh : vw, height: vh <= vw ? vh : vw }}>
        <Game
          update={update}
          socket={socket}
          game="cnljs"
          width={vh <= vw ? vh : vw}
          height={vh <= vw ? vh : vw}
        />
      </div>
      <div style={{ maxWidth: 330 }}>
        {connected ? "Connected" : "Disconnected"}
        <p>{debugtxt}</p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <IconButton
            active={pressedKey === "1"}
            icon={<Icon as={FaSvgIcon} faIcon={faDiceOne} />}
          >
            Napravi novu sobu
          </IconButton>
          <IconButton
            active={pressedKey === "2"}
            icon={<Icon as={FaSvgIcon} faIcon={faDiceTwo} />}
          >
            Igraj samo na tv-u sa daljinskim
          </IconButton>
          <IconButton
            active={pressedKey === "3"}
            icon={<Icon as={FaSvgIcon} faIcon={faDiceThree} />}
          >
            skeniraj qr kod na mobitelu
          </IconButton>
          <IconButton
            active={pressedKey === "4"}
            icon={<Icon as={FaSvgIcon} faIcon={faDiceFour} />}
          >
            koristi mobitel kao daljinski
          </IconButton>
          <IconButton
            active={pressedKey === "5"}
            icon={<Icon as={FaSvgIcon} faIcon={faDiceFive} />}
          >
            posjeti patreon stranicu
          </IconButton>
          <IconButton
            active={pressedKey === "6"}
            icon={<Icon as={FaSvgIcon} faIcon={faDiceSix} />}
          >
            baci kocku
          </IconButton>
        </div>
      </div>
    </div>
  );
};
export default TV;

import React from "react";

import Cnljs from "../GameK";
// import CW from "../GameCW";

const GamePicker = (props) => {
  return <Cnljs update={props.update} socket={props.socket} />;
  // return (props.game=="cnljs")?
  //   <Cnljs update={props.update} socket={props.socket} />
  // :
  //   <CW  update={props.update} socket={props.socket} />
};

export default GamePicker;

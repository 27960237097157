import handleResponse from './handleResponse';

const userService = {
    getRewards,
    claim,
    impression
};

function getRewards() {
    const requestOptions = { method: 'GET', credentials: 'include' };
    return fetch(`${process.env.REACT_APP_ROOT}/api/rewards/`, requestOptions).then(handleResponse);
}

function claim(id, code) {
  console.log("claiming reward", id);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({rewardId:id, code: code})
  };

  return fetch(`${process.env.REACT_APP_ROOT}/api/rewards/claim`, requestOptions)
    .then(handleResponse)
    .catch(e=>{
      console.log("should return error", e);
      return Promise.reject(e);
    });
}

function impression() {
  console.log("count impression");
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include'/*,
    body: JSON.stringify({rewardId:id})*/
  };

  return fetch(`${process.env.REACT_APP_ROOT}/api/rewards/impression`, requestOptions)
    .then(handleResponse)
    .catch(e=>{
      console.log("should return error", e);
      return Promise.reject(e);
    });
}

export default userService

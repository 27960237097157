import UIfx from 'uifx';

const fileNames = [
  "all-eyes-on-me-465.mp3",
  "decay-475.mp3",
  "relentless-572.mp3",
  "arpeggio-467.mp3",
  "for-sure-576.mp3",
  "served-504.mp3",
  "awareness-469.mp3",
  "get-outta-here-505.mp3",
  "springtime-486.mp3",
  "beyond-doubt-580.mp3",
  "graceful-558.mp3",
  "stairs-567.mp3",
  "capisci-521.mp3",
  "hiccup-483.mp3",
  "you-know-508.mp3",
  "congratulations-454.mp3",
  "just-maybe-577.mp3",
  "your-turn-491.mp3",
  "credulous-512.mp3",
  "pedantic-490.mp3",
  "thunder.mp3"
]

const gameplayFiles = [
  "chime-351.mp3",
  "short-notice-361.mp3",
  "take-this-313.mp3",
  "smrtonosnasorka.mp3"
]

const tick = new UIfx(
  "/sounds/notifications/"+fileNames[0],
  {
    volume: 0.9, // value must be between 0.0 ⇔ 1.0
    throttleMs: 50
  }
)

const ticks = [];
for(let s=0;s<fileNames.length;s++){
  ticks[s] = new UIfx(
    "/sounds/notifications/"+fileNames[s],
    {
      volume: 0.9, // value must be between 0.0 ⇔ 1.0
      throttleMs: 50
    }
  )
}

const gameSounds = {
  turn: new UIfx(`/sounds/gameplay/${gameplayFiles[0]}`, {volume: 0.9, throttleMs: 50}),
  dice: new UIfx(`/sounds/gameplay/${gameplayFiles[2]}`, {volume: 0.9, throttleMs: 50}),
  elmuerte: new UIfx(`/sounds/gameplay/${gameplayFiles[3]}`, {volume: 0.9, throttleMs: 5000})
}

const playSound = ()=>{
  tick.play();
}

const playSpecific = sound => {
  if(!loadSettings().sounds.mute){
    ticks[sound].play();
  }
}

const loadSettings = ()=>{
  let initialValue = {
    sounds: {
      mute: false,
      chatSound: 0
    }
  }
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(process.env.REACT_APP_SETTINGS_KEY);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    // If error also return initialValue
    console.log(error);
    window.localStorage.removeItem(process.env.REACT_APP_SETTINGS_KEY);
    return initialValue;
  }
}

const playGameSound = (sound)=>{
  if(!loadSettings().sounds.mute){
    gameSounds[sound].play();
  }
}

export default {
  playSound: playSound,
  allSounds: fileNames,
  playSpecific: playSpecific,
  playGameSound: playGameSound
}

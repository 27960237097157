const createjs = window.createjs;

const manifest = [
  {src: "Lik_metla_crveni.png", id: "red_broom"},
  {src: "Lik_metla_plavi.png", id: "blue_broom"},
  {src: "Lik_metla_zuti.png", id: "yellow_broom"},
  {src: "Lik_metla_zeleni.png", id: "green_broom"},
  {src: "Lik_sheriff_crveni.png", id: "red_sheriff"},
  {src: "Lik_sheriff_plavi.png", id: "blue_sheriff"},
  {src: "Lik_sheriff_zuti.png", id: "yellow_sheriff"},
  {src: "Lik_sheriff_zeleni.png", id: "green_sheriff"},
  {src: "Lik_devil_crveni.png", id: "red_devil"},
  {src: "Lik_devil_plavi.png", id: "blue_devil"},
  {src: "Lik_devil_zuti.png", id: "yellow_devil"},
  {src: "Lik_devil_zeleni.png", id: "green_devil"},
  {src: "Lik_terminator_crveni.png", id: "red_terminator"},
  {src: "Lik_terminator_plavi.png", id: "blue_terminator"},
  {src: "Lik_terminator_zuti.png", id: "yellow_terminator"},
  {src: "Lik_terminator_zeleni.png", id: "green_terminator"},
  {src: "Lik_ghandi_crveni.png", id: "red_ghandi"},
  {src: "Lik_ghandi_plavi.png", id: "blue_ghandi"},
  {src: "Lik_ghandi_zuti.png", id: "yellow_ghandi"},
  {src: "Lik_ghandi_zeleni.png", id: "green_ghandi"},
  {src: "Lik_jason_crveni.png", id: "red_jason"},
  {src: "Lik_jason_plavi.png", id: "blue_jason"},
  {src: "Lik_jason_zuti.png", id: "yellow_jason"},
  {src: "Lik_jason_zeleni.png", id: "green_jason"},
  {src: "Lik_vrisak_crveni.png", id: "red_screem"},
  {src: "Lik_vrisak_plavi.png", id: "blue_screem"},
  {src: "Lik_vrisak_zuti.png", id: "yellow_screem"},
  {src: "Lik_vrisak_zeleni.png", id: "green_screem"},
  {src: "Lik_mraz_crveni.png", id: "red_santa"},
  {src: "Lik_mraz_plavi.png", id: "blue_santa"},
  {src: "Lik_mraz_zuti.png", id: "yellow_santa"},
  {src: "Lik_mraz_zeleni.png", id: "green_santa"},
  {src: "Dice-1.png", id: "dice1"},
  {src: "Dice-2.png", id: "dice2"},
  {src: "Dice-3.png", id: "dice3"},
  {src: "Dice-4.png", id: "dice4"},
  {src: "Dice-5.png", id: "dice5"},
  {src: "Dice-6.png", id: "dice6"},
  {src: "Dice-anim.png", id: "diceAnim"},
  {src: "boardBg.jpg", id: "boardBg"},
  {src: "x.png", id: "x"},
  {src: "nameTag.png", id: "nameTag"}
];
const loader = new createjs.LoadQueue(true);
let loaded = false;

export default {
  getResult: (asset) => {
    return loader.getResult(asset);
  },
  getLoader: () => {
    return loader;
  },
  loadAssets: () => {
    if(!loaded){
      loader.loadManifest(manifest, true, "/assets/");
      loaded=true;
    }
  }
}

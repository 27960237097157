const loadGDsdk = (load, callback) => {
  const existingScript = document.getElementById('gamedistribution-jssdk');
  if (!existingScript && load) {
    const script = document.createElement('script');
    script.src = 'https://html5.api.gamedistribution.com/main.min.js';
    script.id = 'gamedistribution-jssdk';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
    script.onerror = (e)=>{
      console.log("gdsdk error", e);
    }
  }
  if (existingScript && callback)
    callback();
};

export default loadGDsdk;

import { getBezier, Point } from "../engine";
import loaderSvc from "../loaderSvc";
const createjs = window.createjs;

const board = new createjs.Shape();
const bbg = new createjs.Bitmap(loaderSvc.getResult("boardBg"));
const fields = new createjs.Shape();
const turnIndicator = new createjs.Shape();
const scale = {
  x: 1,
  y: 1,
};
bbg.scale = scale.x;

//field drawing
const fieldCoords = [];
const bigCircle = [];
const deathCircle = [];
const finishFields = [];
const rhCoords = [];
const bhCoords = [];
const yhCoords = [];
const ghCoords = [];

let width = 500 * scale.x;
let height = 500 * scale.y;

const ogHeight = 500;
const ogWidth = 500;

let type = -1;
let turn = 0;

rhCoords.push(
  { x: 50 * scale.x, y: 138 * scale.y },
  { x: 85 * scale.x, y: 125 * scale.y },
  { x: 113 * scale.x, y: 102 * scale.y },
  { x: 135 * scale.x, y: 70 * scale.y },
);

bhCoords.push(
  { x: (ogWidth - 50) * scale.x, y: 138 * scale.y },
  { x: (ogWidth - 85) * scale.x, y: 125 * scale.y },
  { x: (ogWidth - 113) * scale.x, y: 102 * scale.y },
  { x: (ogWidth - 135) * scale.x, y: 70 * scale.y },
);

yhCoords.push(
  { x: (ogWidth - 50) * scale.x, y: (ogHeight - 138) * scale.y },
  { x: (ogWidth - 85) * scale.x, y: (ogHeight - 125) * scale.y },
  { x: (ogWidth - 113) * scale.x, y: (ogHeight - 102) * scale.y },
  { x: (ogWidth - 135) * scale.x, y: (ogHeight - 70) * scale.y },
);

ghCoords.push(
  { x: 50 * scale.x, y: (ogHeight - 138) * scale.y },
  { x: 85 * scale.x, y: (ogHeight - 125) * scale.y },
  { x: 113 * scale.x, y: (ogHeight - 102) * scale.y },
  { x: 135 * scale.x, y: (ogHeight - 70) * scale.y },
);

const resetHomeCoords = () => {
  rhCoords[0].x = 50 * scale.x;
  rhCoords[0].y = 138 * scale.y;
  rhCoords[1].x = 85 * scale.x;
  rhCoords[1].y = 125 * scale.y;
  rhCoords[2].x = 113 * scale.x;
  rhCoords[2].y = 102 * scale.y;
  rhCoords[3].x = 135 * scale.x;
  rhCoords[3].y = 70 * scale.y;

  bhCoords[0].x = (ogWidth - 50) * scale.x;
  bhCoords[0].y = 138 * scale.y;
  bhCoords[1].x = (ogWidth - 85) * scale.x;
  bhCoords[1].y = 125 * scale.y;
  bhCoords[2].x = (ogWidth - 113) * scale.x;
  bhCoords[2].y = 102 * scale.y;
  bhCoords[3].x = (ogWidth - 135) * scale.x;
  bhCoords[3].y = 70 * scale.y;

  yhCoords[0].x = (ogWidth - 50) * scale.x;
  yhCoords[0].y = (ogHeight - 138) * scale.y;
  yhCoords[1].x = (ogWidth - 85) * scale.x;
  yhCoords[1].y = (ogHeight - 125) * scale.y;
  yhCoords[2].x = (ogWidth - 113) * scale.x;
  yhCoords[2].y = (ogHeight - 102) * scale.y;
  yhCoords[3].x = (ogWidth - 135) * scale.x;
  yhCoords[3].y = (ogHeight - 70) * scale.y;

  ghCoords[0].x = 50 * scale.x;
  ghCoords[0].y = (ogHeight - 138) * scale.y;
  ghCoords[1].x = 85 * scale.x;
  ghCoords[1].y = (ogHeight - 125) * scale.y;
  ghCoords[2].x = 113 * scale.x;
  ghCoords[2].y = (ogHeight - 102) * scale.y;
  ghCoords[3].x = 135 * scale.x;
  ghCoords[3].y = (ogHeight - 70) * scale.y;
};

const createHomeBase = (target, strokeColor, fillColor, x, y) => {
  target.graphics.beginStroke(strokeColor).setStrokeStyle(5);
  target.graphics.beginFill(fillColor);
  target.graphics.drawCircle(x * scale.x, y * scale.y, 150 * scale.x);
};

const createMask = () => {
  var maskBox = new createjs.Shape();
  maskBox.graphics.beginFill("#00000000");
  maskBox.graphics.drawRect(0, 0, ogWidth * scale.x, ogHeight * scale.y);
  //maskBox.cache(0, 0, width, height);
  return maskBox;
};
const addToStage = (stage) => {
  stage.addChild(bbg);
  stage.addChild(board);
  stage.addChild(fields);
  stage.addChild(turnIndicator);
};

const removeFromStage = (stage) => {
  stage.removeChild(bbg);
  stage.removeChild(board);
  stage.removeChild(turnIndicator);
};
const getWidth = () => {
  return width * board.scaleX;
};
const getHeight = () => {
  return height * board.scaleY;
};
const getX = () => {
  return board.x * scale.x;
};
const setX = (val) => {
  board.x = val * scale.x;
};
const getY = () => {
  return board.y * scale.x;
};
const setType = (t) => {
  type = t;
  if (type == 1) {
    setClassic();
  } else if (type == 2) {
    setElMuerte();
  }
};
const setY = (val) => {
  bbg.y = (val - 1) * scale.y;
  board.y = val * scale.y;
  fields.y = (val + 1) * scale.y;
};
const getHomePositions = (color) => {
  switch (color) {
    case 0:
      return rhCoords;
    case 1:
      return bhCoords;
    case 2:
      return yhCoords;
    case 3:
      return ghCoords;
  }
};
const getPositionCoords = (pos) => {
  //console.log("fieldCoords[pos]: ", pos, fieldCoords[pos]);
  return fieldCoords[pos];
};
const setTurn = (currentTurn) => {
  if (turn == currentTurn) {
    return turn;
  }
  turn = currentTurn;
  var strokeColor, fillColor;
  switch (currentTurn) {
    case 0:
      fillColor = "red";
      strokeColor = "#660000";
      break;
    case 1:
      fillColor = "blue";
      strokeColor = "#000066";
      break;
    case 2:
      fillColor = "yellow";
      strokeColor = "#FFCC00";
      break;
    case 3:
      fillColor = "green";
      strokeColor = "#006600";
      break;
    default:
      fillColor = "gray";
      strokeColor = "#333333";
      break;
  }
  turnIndicator.graphics.clear();
  turnIndicator.graphics.drawCircle(
    (ogWidth / 2) * scale.x,
    (ogHeight / 2) * scale.y,
    50 * scale.x,
  );
  turnIndicator.graphics.beginStroke(strokeColor).setStrokeStyle(5);
  turnIndicator.graphics.beginFill(fillColor);
  turnIndicator.graphics.drawCircle(
    (ogWidth / 2) * scale.x,
    (ogHeight / 2) * scale.y,
    50 * scale.x,
  );
  //board.updateCache();
};

const setClassic = () => {
  type = 1;
  fieldCoords.length = 0;
  fields.graphics.clear();
  for (var f = 0; f < bigCircle.length; f++) {
    fieldCoords.push(bigCircle[f]);
  }
  for (f = 0; f < finishFields.length; f++) {
    fieldCoords.push(finishFields[f]);
  }
  drawFields(fieldCoords, fields, width, height);
};

const setElMuerte = () => {
  type = 2;
  fieldCoords.length = 0;
  fields.graphics.clear();
  for (var f = 0; f < bigCircle.length; f++) {
    fieldCoords.push(bigCircle[f]);
  }
  for (f = 0; f < deathCircle.length; f++) {
    fieldCoords.push(deathCircle[f]);
  }
  drawFields(fieldCoords, fields, width, height);
};

const setElMuerteCircle = (field) => {
  var deathCircle = [];
  for (var p = 0; p < 13; p++) {
    var pos = { x: 0, y: 0 };
    pos.x = width / 2 + 100 * Math.cos(((2 * Math.PI) / 13) * p); // scale radius, which i think is 100 here, unless that is an offset
    pos.y = height / 2 + 100 * Math.sin(((2 * Math.PI) / 13) * p);
    pos.color = p == field - 1 ? "red" : false;
    deathCircle.push(pos);
  }
  fieldCoords.length = 0;
  fields.graphics.clear();
  for (var f = 0; f < deathCircle.length; f++) {
    fieldCoords.push(deathCircle[f]);
  }
  drawFields(fieldCoords, fields, width, height);
};
const drawFields = (fieldCoords, target) => {
  for (var p = 0; p < fieldCoords.length; p++) {
    drawField(target, fieldCoords[p].x, fieldCoords[p].y, fieldCoords[p].color);
  }
};
const drawField = (target, x, y, color) => {
  //target.arc(x, y, 15, 0, 2 * Math.PI, false);
  if (color) target.graphics.beginFill(color);
  else target.graphics.beginFill("white");
  var colorCode = "#333333";
  switch (color) {
    case "red":
      colorCode = "#330000";
      break;
    case "blue":
      colorCode = "#000033";
      break;
    case "yellow":
      colorCode = "#FFCC00";
      break;
    case "green":
      colorCode = "#003300";
      break;
    default:
      break;
  }
  // target.graphics.beginStroke(colorCode).setStrokeStyle(2);
  target.graphics.drawCircle(x * scale.x, y * scale.y, 15);
  //target.graphics.drawEllipse(x-15,y-10,30,20);
};

createHomeBase(board, "#330000", "red", 0, 0);
createHomeBase(board, "#000033", "blue", ogWidth, 0);
createHomeBase(board, "#FFCC00", "yellow", ogWidth, ogHeight);
createHomeBase(board, "#003300", "green", 0, ogHeight);

for (var p = 0; p < 9; p++) {
  var pos = getBezier(
    (8 - p) / 9,
    Point(0 - 20, ogHeight / 2 - 50),
    Point(ogWidth / 4 - 20, ogHeight / 2),
    Point(ogWidth / 2 - 40, ogHeight / 4),
    Point(ogWidth / 2 - 40, 20),
  );
  pos.color = p == 0 ? "red" : false;
  bigCircle.push(pos);
}
bigCircle.push({ x: ogWidth / 2, y: 20 });
for (var p = 0; p < 9; p++) {
  var pos = getBezier(
    (8 - p) / 9,
    Point(ogWidth / 2 + 40, -15),
    Point(ogWidth / 2 + 40, ogHeight / 4 - 40),
    Point(ogWidth / 2 + ogWidth / 4, ogHeight / 2 - 40),
    Point(ogWidth - 20, ogHeight / 2 - 40),
  );
  pos.color = p == 0 ? "blue" : false;
  bigCircle.push(pos);
}
bigCircle.push({ x: ogWidth - 20, y: ogHeight / 2 });
for (var p = 0; p < 9; p++) {
  var pos = getBezier(
    (8 - p) / 9,
    Point(ogWidth + 20, ogHeight / 2 + 40),
    Point(ogWidth / 2 + ogWidth / 4, ogHeight / 2 + 20),
    Point(ogWidth / 2 + 40, ogHeight / 2 + ogHeight / 4 - 20),
    Point(ogWidth / 2 + 40, ogHeight - 20),
  );
  pos.color = p == 0 ? "yellow" : false;
  bigCircle.push(pos);
}
bigCircle.push({ x: ogWidth / 2, y: ogHeight - 20 });
for (var p = 0; p < 9; p++) {
  var pos = getBezier(
    (8 - p) / 9,
    Point(ogWidth / 2 - 40, ogHeight + 10),
    Point(ogWidth / 2 - 10, ogHeight / 2 + ogHeight / 4 + 40),
    Point(ogWidth / 4 - 10, ogHeight / 2 + 40),
    Point(20, ogHeight / 2 + 40),
  );
  pos.color = p == 0 ? "green" : false;
  bigCircle.push(pos);
}
bigCircle.push({ x: 20, y: ogHeight / 2 });

for (var rh = 4; rh > 0; rh--) {
  finishFields.push({
    x: ogWidth / 2 - (ogWidth / 12) * (rh - 1) - 70,
    y: ogHeight / 2,
    color: "red",
  });
}
for (var bh = 4; bh > 0; bh--) {
  finishFields.push({
    x: ogWidth / 2,
    y: ogHeight / 2 - (ogWidth / 12) * (bh - 1) - 70,
    color: "blue",
  });
}
for (var yh = 4; yh > 0; yh--) {
  finishFields.push({
    x: ogWidth / 2 + (ogWidth / 12) * (yh - 1) + 70,
    y: ogHeight / 2,
    color: "yellow",
  });
}
for (var gh = 4; gh > 0; gh--) {
  finishFields.push({
    x: ogWidth / 2,
    y: ogHeight / 2 + (ogWidth / 12) * (gh - 1) + 70,
    color: "green",
  });
}

const redrawBoard = () => {
  board.graphics.clear();
  resetHomeCoords();
  bbg.scale = scale.x; //what
  createHomeBase(board, "#330000", "red", 0, 0);
  createHomeBase(board, "#000033", "blue", ogWidth, 0);
  createHomeBase(board, "#FFCC00", "yellow", ogWidth, ogHeight);
  createHomeBase(board, "#003300", "green", 0, ogHeight);
};

const setScale = (s) => {
  scale.x = s.x;
  scale.y = s.y;
  setTurn(1);
  redrawBoard();
};

const constructor = () => {
  setTurn(0);

  board.filters = [new createjs.AlphaMaskFilter(createMask().cacheCanvas)];
  board.cache(0, 0, ogWidth * scale.x, ogHeight * scale.y);
  setClassic();
};

export default {
  board: board,
  constructor: constructor,
  addToStage: addToStage,
  getHomePositions: getHomePositions,
  getY: getY,
  setY: setY,
  getWidth: getWidth,
  getHeight: getHeight,
  setScale: setScale,
};

import React, {useContext} from 'react';
import { useLocation } from "react-router-dom";
import {
  Button,
  Progress
} from 'rsuite';
import { CentralContext } from "../../context";

const { Circle } = Progress;
const style = {
  width: 120,
  display: 'inline-block',
  marginRight: 10
};

const BannedPage = props => {
  const cntx = useContext(CentralContext);
  const location = useLocation();
  const params = location.state;

  return (
    <div>
      imate zabranu {/*JSON.stringify(params)*/}
      <p>Vrijednost vašeg profila je: {params.gifts.chocolate*10+params.gifts.rose*100+params.gifts.diamond*1000+params.adsWatched}</p>
      <p>Zabranu možete skinuti dukatima, ukoliko ih imate</p>
      <div style={style}>
        <Circle percent={((params.ducats/params.priceOnHead)*100>=100?100:(params.ducats/params.priceOnHead)*100)} />
        <Button appearance="primary" disabled={params.ducats<params.priceOnHead}>Plati Dukatima</Button>
      </div>
      <p>Ako nemate dukate, možete gledati reklame dok ne skupite dovoljno</p>
      <div style={style}>
        <Circle percent={(params.adsWatched/params.priceOnHead)*100} />
        <Button appearance="primary" onClick={e=>{window.gdsdk.showAd('rewarded')}}>Upali Reklamu</Button>
      </div>
      <p>ili ispuniti anketu</p>
      <div>
        <iframe width="100%" frameBorder="0" height="500px"  src={`https://offers.cpx-research.com/index.php?app_id=7494&ext_user_id=${cntx.user.id}&secure_hash=ofrXkHGoGsvfIpyOl4eJMt4TFaMptjqq&username=${(cntx?.user?.basic?.name)?cntx.user.basic.name:"player"}`}></iframe>
      </div>
      <p>Daleko najlakši način skidanja zabrane je uplata, možete uplatiti na <a href="https://patreon.com/iggyZiggy">patreonu</a> preko bankovne kartice ili kupnjom nekog prepaid bona kao xbon ako ste iz Bosne ili paysafecard-om ako je dostupno u vašoj državi (Hrvatska, Srbija, EU, US)</p>
      {/* xbon upis ili paysafecard */}
      <p>Pokloni dižu vrijednost vašeg profila i da bi vam netko zabranio pristup igri, mora platiti više nego što vaš profil vrijedi. Budući da ste dobili zabranu, možete prodati poklone ukoliko ih imate da bi platili skidanje zabrane.</p>
      <p>Ako ne želite da vam pada vrijednost profila, kupite još dukata.</p>
      <div style={{display:"flex", flexDirection:"column"}}>
        <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
          <div style={{display:"flex", flexDirection:"column", alignItems:"center"}} onClick={(e)=>{}}>
            <p style={{fontSize:"xxx-large"}}>🍫</p>
            <div style={{display:"flex", alignItems:"center"}}>
              <img src="/images/yelo.png" style={{width:20, height:20}}/>
              <p style={{marginLeft:5}}>{params.gifts.chocolate*10}</p>
            </div>
          </div>
          <Button style={{height:40, marginLeft: 10}}>Prodaj</Button>
        </div>
        <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
          <div style={{display:"flex", flexDirection:"column", alignItems:"center", margin:"0 5px"}} onClick={(e)=>{}}>
            <p style={{fontSize:"xxx-large"}}>🌹</p>
            <div style={{display:"flex", alignItems:"center"}}>
              <img src="/images/yelo.png" style={{width:20, height:20}}/>
              <p style={{marginLeft:5}}>{params.gifts.rose*100}</p>
            </div>
          </div>
          <Button style={{height:40, marginLeft: 10}}>Prodaj</Button>
        </div>
        <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
          <div style={{display:"flex", flexDirection:"column", alignItems:"center"}} onClick={(e)=>{}}>
            <p style={{fontSize:"xxx-large"}}>💎</p>
            <div style={{display:"flex", alignItems:"center"}}>
              <img src="/images/yelo.png" style={{width:20, height:20}}/>
              <p style={{marginLeft:5}}>{params.gifts.diamond*1000}</p>
            </div>
          </div>
          <Button style={{height:40, marginLeft: 10}}>Prodaj</Button>
        </div>
      </div>
    </div>
  )
}

export default BannedPage;

import React, { useState, useEffect } from 'react';
import { Group, Circle, Text, Image } from "react-konva";
import HomeCoords from "./homeCoords";
import useImage from "use-image";
import userService from "../../../services/user.service";

const Home = props =>{
  const [name, setName] = useState("-slobodno-");
  const coords = HomeCoords[props.color];
  const [picUrl, setPicUrl] = useState("");
  const [image] = useImage(picUrl);

  useEffect(()=>{
    if(props.update?.players?.[props.color]?.name && props.update?.players?.[props.color]?.unset!==true){
      setName(props.update.players[props.color].name);
      /*userService.getLargePicture(props.update.players[props.color].id)
      .then(res=>{
        setPicUrl(res.url);
      })
      .catch(e=>{
        console.log("no image", e);
      });*/
    } else /*if(props.update?.players?.[props.color]?.unset)*/{
      setName("-slobodno-");
      //setPicUrl("https://upload.wikimedia.org/wikipedia/commons/c/ca/1x1.png");
    }
  }, [props.update])

  return (
    <Group clip={{x:coords.clip.x,y:coords.clip.y,width:150, height:150}}>
      <Circle x={coords.circle.x} y={coords.circle.y} fill={props.color} radius={150} />
      {/*!!(picUrl.length) && <Image visible={!!picUrl.length} image={image} x={coords.clip.x} y={coords.clip.y}/>*/}
      <Text text={name} x={(coords.text.x==0)?10:coords.text.x} y={coords.text.y}/>
    </Group>
  );
}

export default Home;

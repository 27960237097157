import React, { useState, useEffect } from 'react';
import userService from "../../services/user.service";

import styles from "./index.module.css";

const ProfilePage = props => {
  const [user, setUser] = useState(false);
  const [error, setError] = useState(false);

  useEffect(()=>{
    userService.getUserWithId(props.match.params.dbid)
    .then(user=>{
      console.log("got user", user);
      if(user.success===false){
        setError({msg: user.msg})
      } else {
        setUser(user.data);
      }
    })
    .catch(e=>{
      setError(e);
    })
  }, []);

  return (
    <div>
      {!error && <div>
        {user && <div>
          <div className={styles.picAndName}>
            <img src={user.basic.picture}/>
            <p>{user.basic.name}</p>
          </div>
          <p>Na igri od: {(new Date(user.basic.createdDate)).toLocaleDateString("hr-HR", {year: 'numeric', month: 'long', day: 'numeric' })}</p>
          <p>Zadnji puta na igri: {(new Date(user.stats.lastVisit)).toLocaleDateString("hr-HR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
          <p>Dukata: {user.ducats}</p>
          <p>Premium: {(user.premium)?"da":"ne"}</p>
          <p>Otključane Figure: {user.figures}</p>
          <p>Osvojene Nagrade: {user.rewards}</p>
          <p>Blok Lista: {user.stats.blockedUsers}/{user.blockLimit}</p>
        </div>}
        {!user && <div>
          učitavanje profila...
        </div>}
      </div>}
      {error && <p>
        {error.msg}
      </p>}
    </div>
  )
}

export default ProfilePage;
